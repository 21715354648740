import React from "react";
import {Helmet} from "react-helmet";
import Featuress from "../../Features/Features";
import PricingSnippet from "../../Universal/PricingSnippet";

import FeatureBanner from "../../../assets/imgs/Features/ATC/AT-Maindashboard.png";
import PlaningImg from "../../../assets/imgs/planning image.png";
import accomodate from "../../../assets/imgs/accomodate.png";



import AppUsage from '../../../assets/imgs/Features/ATC/AT-App-Usage.png';
import TrackingProductivity from '../../../assets/imgs/Features/ATC/AT-Tracking-Productivity.png';

import ReportsSummary from '../../../assets/imgs/Features/ATC/AT-ReportsSummary.png';




function ApplicationT() {
  const priceCard = {
    heading: "Turbocharge your productivity now with our free plan.",
    desc: "No upfront fee, hidden charges, or cost overrun. Do more with less while sticking to your budget. Literally.    ",
  };

  var head = {
    title: `Automated App Tracking
    `,
    desc: `StickyTasks makes app tracking for organizations simple and necessary. End-users can log tasks and start tracking user activity with just a click.
    `,
    img: FeatureBanner,
  };

  var sections = [
    {
      img: AppUsage,
      title: `App Usage
      `,
      desc: `StickyTasks promotes accountability and allows managers to keep close tabs on what the employees are doing and what projects are being worked on. Managers can easily track which apps and website take most user-time.
      `,
    },
    {
      img: TrackingProductivity,
      title: `Tracking Productivity
      `,
      desc: `You’ll find a productivity analysis in the StickyTasks dashboard, which both employees and managers have access to help you and your team on track.
      `,
    },
    {
      img: ReportsSummary,
      title: `Reports Summary
      `,
      desc: `You can have access to easy-to-read reports about time spent on each project. To further assist managers, StickyTasks has daily, weekly, and monthly summarized reports for quick user behavior patterns.
      `,
    },
  ];

  return (
    <div>
      <Helmet>
            <title>Application Tracking Software: Keep Track of Your Applications - StickyTasks</title>
            <meta name="description" content="Keep track of job applications and resumes with StickyTasks' application tracking feature." />
      </Helmet>
      <Featuress headerData={head} sectionData={sections} />
      <PricingSnippet heading={priceCard.heading} desc={priceCard.desc} />
    </div>
  );
}

export default ApplicationT;
