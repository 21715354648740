import React from "react";
import { Helmet } from "react-helmet";
import HeroBanner from "../../assets/imgs/helpcenter/bg.svg";
import Addtools from "../../assets/imgs/helpcenter/add_tools.png";
import GettingStarted from "../../assets/imgs/helpcenter/getting_started.png";
import Tips from "../../assets/imgs/helpcenter/tips_tricks.png";
import Stikcy from "../../assets/imgs/helpcenter/using-slack.png";
import Workspace from "../../assets/imgs/helpcenter/workspace.png";
import Yourprofile from "../../assets/imgs/helpcenter/your_profile.png";
import { Container, Navbar, Nav, NavDropdown, Row, Col, NavLink } from "react-bootstrap";
import { hover } from "@testing-library/user-event/dist/hover";
import css from "./HelpC.css";
import Onboardguide from "../pages/helpcenter-pages/Onboard-guide";
import HowCanAssignTask from "../pages/helpcenter-pages/HowCanAssignTask";
import HowCanICreate from "../pages/helpcenter-pages/HowCanICreate";

import Img1 from "../../assets/imgs/Helpcenter-features/1.webp";
import Img2 from "../../assets/imgs/Helpcenter-features/2.webp";
import Img3 from "../../assets/imgs/Helpcenter-features/3.webp";
import Img4 from "../../assets/imgs/Helpcenter-features/4.webp";
import Img5 from "../../assets/imgs/Helpcenter-features/5.webp";

import HCV from "../../assets/imgs/helpcenter/hcv.png"

import { LinkContainer } from "react-router-bootstrap";




const HelpCenter = () => {
  const heroSectionStyle = {
    height: "70vh",
    width: "100%",
    backgroundImage: `url(${HeroBanner})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",

    alignItems: "center",
    justifyContent: "center",


  };

  const headingStyle = {
    color: "var(--black)",
  };

  const ctnStyle = {
    background: "#fff",
  };

  const colStyle = {
    borderBottom: "1px solid #E3E3E3",
    borderRight: "1px solid #E3E3E3",
    transition: ".3s",
    paddingBottom: "4rem",
    textAlign: "center",
  };

  const imgStyle = {
    transform: "scale(0.5)",
  };

  const textStyle = {
    width: "80%",
    margin: "auto",
    marginTop: "1rem",
  };







  const borderFix = {
    borderBottom: "1px solid #E3E3E3",
    textAlign: "center",
    transition: ".3s"
  };

  const listyle = {
    listyle: "none",
    padding: "0px",
    color: "black"
  };

  const imgwidth = {
    width: "100%",
  }

  return (
    <div style={ctnStyle} className="helpcenter-ctn">
      <Helmet>
        <title>StickyTasks Help Center: Find Answers to Your Questions</title>
        <meta name="description" content="Need assistance with StickyTasks? Our help center has all the resources you need to get started." />
      </Helmet>
      <div style={heroSectionStyle}>
        <h2 style={headingStyle}>StickyTasks: Help Desk
        </h2>

        <h5>Let’s Make Your World Productive
        </h5>


        <input type="text" placeholder="Search Artical Here!" className="inputStyle" />



      </div>



      <div className="ctnInside">


        <div>


          <h2>User Handbook: Getting Started with StickyTasks
          </h2>

          <h5>Let’s show you around StickyTasks!
          </h5>

          <li style={listyle}>

            <LinkContainer to="/pages/helpcenter-pages/Onboard-guide" style={listyle}>
              <Nav.Link>Onboarding Guide: Starting with The Basics</Nav.Link>
            </LinkContainer>
          </li>


          <li>
            <LinkContainer to="/pages/helpcenter-pages/HowCanAssignTask" style={listyle}>
              <Nav.Link> How Can I Assign Tasks to My Teammates?</Nav.Link>
            </LinkContainer>




          </li>

          <li href="#">
            <LinkContainer to="/pages/helpcenter-pages/HowCanICreate" style={listyle}>
              <Nav.Link>
                How Can I Create a New Workspace?
              </Nav.Link>
            </LinkContainer>
          </li>
        </div>
        <div>


          <h2>Features: Top Tools & Aspects of StickyTasks

          </h2>

          <li href="#">Setting Dashboards to Visualize Data Properly</li>
          <img src={HCV} style={imgwidth} />

          <li href="#">Real-Time: Stay up-to-date on Your Team Work Status</li>
          <img src={Img2} style={imgwidth} />

          <li href="#">My Tasks: Create Whiteboard Mind Map</li>
          <img src={Img3} style={imgwidth} />

          <li href="#">My Projects: Get a 360 View of All Your Projects in One Unified Space
          </li>
          <img src={Img4} style={imgwidth} />

          <li href="#">Team Attendance: View, Create & Edit Time Entries</li>
          <img src={Img5} style={imgwidth} />




        </div>



        <div>

          <h2>Use Cases: Ideas Specific to Industry or Professional Field</h2>


          <li href="#">Creating Product Briefs</li>

          <li href="#">StickyTasks for Product Managers</li>

          <li href="#">StickyTasks for Students</li>

          <li href="#">StickyTasks for Retail Operations</li>

          <li href="#">How To Track Inventory with StickyTasks</li>

          <li href="#">Recruitment and StickyTasks</li>



        </div>


        <div>

          <h2>General:</h2>

          <li href="#">Update two-factor authentication</li>

          <li href="#">See Who Has Access to Your Workspace</li>

          <li href="#">Share your Feedback and Suggestions with StickyTasks</li>
        </div>


        {/* <Row>
          <Col style={colStyle} className="box">
            <img src={GettingStarted} style={imgStyle} />
            <h4>Getting started</h4>
            <p style={textStyle}>
              Everything you need to know to get started and get to work in
              Slack
            </p>
          </Col>
          <Col style={colStyle} className="box">
            <img src={Stikcy} style={imgStyle} />
            <h4>Using StickyTasks</h4>
            <p style={textStyle}>
              From channels to search, learn how Slack works from top to bottom
            </p>
          </Col>
          <Col style={borderFix} className="box">
            <img src={Yourprofile} style={imgStyle} />
            <h4>Your profile & preferences</h4>
            <p style={textStyle}>
              Adjust your profile and preferences to make Slack work just for
              you.
            </p>
          </Col>
        </Row>

        <Row>
          <Col style={colStyle} className="box">
            <img src={GettingStarted} style={imgStyle} />
            <h4>Getting started</h4>
            <p style={textStyle}>
              Everything you need to know to get started and get to work in
              Slack
            </p>
          </Col>
          <Col style={colStyle} className="box">
            <img src={Stikcy} style={imgStyle} />
            <h4>Using StickyTasks</h4>
            <p style={textStyle}>
              From channels to search, learn how Slack works from top to bottom
            </p>
          </Col>
          <Col style={borderFix} className="box">
            <img src={Yourprofile} style={imgStyle} />
            <h4>Your profile & preferences</h4>
            <p style={textStyle}>
              Adjust your profile and preferences to make Slack work just for
              you.
            </p>
          </Col>
        </Row> */}
      </div>
    </div>
  );
};

export default HelpCenter;
