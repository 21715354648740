import React from "react";
import { Container } from "react-bootstrap";
import './single.css';

const SingleImage = (props) => {


 

  return (
    <div>
      <Container fluid className="normal-container text-center single-ctn">
        <h2 >
          {props.heading}
        </h2>
        <img src={props.image} className="imgStyle" />
        <p className="section-description mt-3 textStyle padding-left-right" >
          {props.text}
        </p>
      </Container>
    </div>
  );
};

export default SingleImage;
