import React from "react";
import {Helmet} from "react-helmet";
import Featuress from "../../Features/Features";
import PricingSnippet from "../../Universal/PricingSnippet";

import FeatureBanner from "../../../assets/imgs/FeatureBanner.png";
import PlaningImg from "../../../assets/imgs/planning image.png";
import accomodate from "../../../assets/imgs/accomodate.png";
import ProMang from '../../../assets/imgs/ProMang.png';

import TeamAuto from '../../../assets/imgs/Features/PM/PM-TeamAutomation1.png';
import ProjMang from '../../../assets/imgs/Features/PM/PM-MainDashbaord1.png';
import PlaningSched from '../../../assets/imgs/Features/PM/PM-Planning-Scheduling1.png';
import Accomudation from '../../../assets/imgs/Features/PM/PM-AccommodateDepartments1.png';







function ProjectM() {
  const priceCard = {
    heading: "Turbocharge your productivity now with our free plan.",
    desc: "No upfront fee, hidden charges, or cost overrun. Do more with less while sticking to your budget. Literally.    ",
  };

  var head = {
    title: "Manage Projects Efficiently",
    desc: "Prepare your business for success. Customize the way you work. Keep track of results.",
    img: ProjMang,
  };

  var sections = [
    {
      img: PlaningSched,
      title: `Planning & Scheduling`,
      desc: `Choose from 35+ apps without juggling between emails or spreadsheets. Develop a clear plan of action. Set a visual timeline for goals and automate progress. Define sprint cycles, add custom field data, review dependencies, and stay on target.`,
    },
    {
      img: TeamAuto,
      title: `Team Automation`,
      desc: `Automate repetitive actions to keep projects moving forward. Share files, updates, and feedback in a few clicks. Send reminders, request updates, and approvals. Meet any unique need with less effort and speed up time to market.`,
    },
    {
      img: Accomudation,
      title: `Accommodate Departments`,
      desc: `Oversee content campaigns, creative requests, and product launches. Plan interview processes and store company policies for reference purposes. Monitor prospects through the sales funnel and onboard new clients. All under one roof.`,
    },
  ];

  return (
    <div>
      <Helmet>
            <title>Project Management Tool: Get Organized and Stay on Track - StickyTasks</title>
            <meta name="description" content="Streamline your project management process with StickyTasks' project management features." />
      </Helmet>
      <Featuress headerData={head} sectionData={sections} />
      <PricingSnippet heading={priceCard.heading} desc={priceCard.desc} />
    </div>
  );
}

export default ProjectM;
