import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import cs from "../../assets/imgs/cs.png";
import blogk from "../../assets/imgs/blogk.png";
import kh from "../../assets/imgs/kh.png";
import { Link } from "react-router-dom";
import './Resources.css';


const Know = () => {
 
 
 


  const seeMore = {
    border: "none",
    background: "transparent",
    textAlign: "left",
    marginTop: "3rem",
    fontWeight: "500",
    padding: "0px",
    fontSize: "16px",
    textDecoration: "underline",
    color: "var(--light-gray)",
  };

  const textInsideStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  };
  return (
    <Container className="section-container mt-4
     resources-ctn">
      <div>
        <h2  className="section-heading headingStyle">
          Expand your knowledge with the latest blogs, informative how-to posts,
          insights, FAQs, and more.
        </h2>
        {/* <p style={textStyle} className="section-description">
          There are many variations of passages of Lorem Ipsum available, but
          the majority have suffered alteration in some form, by injected
          humour, or randomised words which don't look even slightly believable
        </p> */}
      </div>
      <Row className='rowStyle'>
        <Col className='colStyle1'>
          <div style={textInsideStyle}>
            <h5 className="smallcard-heading">Blog</h5>

            <Link to="/blog/" style={seeMore}>See More</Link>
          </div>
        </Col>
        <Col className="colStyle2">
          <div style={textInsideStyle}>
            <h5 className="smallcard-heading">Case Study</h5> 
            <Link to="/case-studies/" style={seeMore}>See More</Link>
          </div>
        </Col>
        <Col className="colStyle3">
          <div style={textInsideStyle}>
            <h5 className="smallcard-heading">Help Center</h5>
            <Link to="/help/" style={seeMore}>See More</Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Know;
