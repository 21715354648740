import React from "react";
import {Helmet} from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";

function PrivacyPolicy() {
  return (
    <div>
      <Helmet>
            <title>StickyTasks Privacy Policy: Protecting Your Data</title>
            <meta name="description" content="Our privacy policy outlines how we collect, use, and protect your personal information." />
      </Helmet>
      <Container fluid className="banner-container">
        <Row>
          <Col>
            <h1 class="entry-title">Privacy Policy</h1>
            <div class="entry-content clear" itemprop="text">
              <p>
                At Sticky Tasks, user data security and privacy are one of our
                top priorities. Sticky Tasks implements some of the high-end
                privacy protocols to protect user data against any kind of cyber
                threats and failures. There-in, this Privacy Policy document has
                all the information regarding how user data is collected,
                processed, stored, and protected.
              </p>

              <p>
                <strong>Key Takeaways:</strong>
              </p>

              <ul>
                <li>Personal Data Collection</li>
                <li>Data Collection Through Third-Party Integrations</li>
                <li>Cookies Consent</li>
                <li>Data Storage</li>
                <li>Data Use &amp; Transfer Protocols</li>
                <li>GDPR Compliant</li>
                <li>Change to Privacy Policy</li>
                <li>Enforcement</li>
              </ul>

              <h1 class="entry-title" id="personal-data-collection">
                Personal Data Collection
              </h1>

              <p>
                There are multiple ways through which Sticky Tasks collect user
                personal data such as information that user provides directly by
                visiting the website or signing up, and collected from third
                parties as well. Furthermore, the collected information is
                further processed by user opt-in consent. Additionally,
                end-users can also cancel their consent any time just by
                contacting us at{" "}
                <a href="mailto:info@stickytasks.com">info@stickytasks.com</a>.
              </p>

              <h1
                class="entry-title"
                id="data-collection-through-third-party-integrations"
              >
                Data Collection Through Third-Party Integrations
              </h1>

              <p>
                Sticky Tasks obtains end-user information from third-party
                integrated services, social media platforms, public databases,
                and from other channel partners. The purpose for accessing this
                data is to update and improve our records, identify prospective
                users, create more personalized marketing strategies and suggest
                services that interest users.
              </p>

              <h1 class="entry-title" id="cookies-consent">
                Cookies Consent
              </h1>

              <p>
                In addition to data collection from third-party platforms,
                StickyTaks captures user data automatically by utilizing session
                storage and background local storage technologies commonly known
                as{" "}
                <strong>
                  <em>Cookies</em>
                </strong>
                , to improve overall user experience. Data stored as cookies
                include user operating system used, and browsing history. Users
                can also restrict their device to save website cookies; however
                this might have an adverse effect on website performance on
                their machine or device.
              </p>

              <p>
                Before using Sticky Tasks, it is advisable to check your current
                browser settings to ensure that you consent for Sticky Tasks to
                save cookies on your device. As explained earlier, if you don’t
                accept or delete our cookies, some areas of the websites might
                take some time to function optimally.
                <strong>Important Note</strong>: This privacy statement doesn’t
                cover the use of third parties’ cookies which we have nocontrol
                over.
              </p>

              <h1 class="entry-title" id="data-storage">
                Data Storage
              </h1>

              <p>
                The company keeps personal data stored for as long as it is
                required to complete their contractual obligations towards the
                user, in line with our Terms of Service. Moreover, we delete or
                anonymize user personal data as soon as the user account is
                terminated, unless it must be kept as an obligation to the
                statutory law.
              </p>

              <h1 class="entry-title" id="data-use-transfer-protocols">
                Data Use &amp; Transfer Protocols
              </h1>

              <p>
                User data at Sticky Tasks is never sold across the internet
                however, to make things work we might need to share user data
                with third-party and service providers. This sharing of data is
                conducted in defined parameters that are enlisted below:
              </p>

              <p>
                <strong>a.</strong> <strong>Business Purposes</strong>
              </p>

              <p>
                Sticky Tasks might share the below-listed categories of user
                personal data for business purposes depending on user status,
                admin or other:
              </p>

              <ul>
                <li>
                  Internet or other electronic network activity data, such as
                  device identifiers, user IP address, version of operating
                  system, type of browser, carrier or manufacturer, and mobile
                  marketing identifiers.
                </li>
                <li>
                  Financial data such as tax information, bank routing numbers,
                  and payment gateways user prefers.
                </li>
                <li>
                  Personal identifiers including user name, email, phone number,
                  and address
                </li>
                <li>
                  Commercial data like transaction history and user feedback
                </li>
              </ul>

              <p>
                These are the main purposes for which we might share user data
              </p>

              <ul>
                <li>Servicing and maintaining user account</li>
                <li>For processing or completing orders</li>
                <li>Delivering quality customer support</li>
                <li>For user identity verification</li>
                <li>
                  To detect any kind of fraudulent activity and its prevention
                </li>
                <li>
                  Providing promotional and marketing, analytics, financing
                  services for Sticky Tasks
                </li>
                <li>
                  For conducting in-depth internal research to develop Sticky
                  Tasks as an all-in work management platform.
                </li>
              </ul>

              <p>
                <strong>b.</strong> <strong>Legal Reasons</strong>
              </p>

              <p>
                One of the main reasons for transferring or sharing user data is
                in response to any federal or state legislation, or if Sticky
                Tasks finds any kind of skeptical activity and decides that
                sharing user personal data is reasonably significant to:
              </p>

              <ul>
                <li>
                  Adhere to applicable governmental reforms (state, federal, and
                  local), sanctioned inquiries (civil, criminal or regulatory),
                  legal or investigation process, or any governmental request
                </li>
                <li>
                  Enforce the implementation of our Service Terms and Conditions
                </li>
                <li>
                  Retort to a legal operations including summons, search
                  warrant, subpoena, or court order
                </li>
                <li>
                  To cooperate with law enforcement organizations for review of
                  any activity that we believe violates federal, state, or local
                  law
                </li>
                <li>
                  To protect against any thing that threatens our rights,
                  exercise or defend legal claims, property and interests.
                </li>
              </ul>

              <h1 class="entry-title" id="gdpr-compliant">
                GDPR Compliant
              </h1>

              <p>
                Sticky Tasks acknowledges the importance of privacy and security
                for users. We ensure compliance with the <strong>EU’s</strong>
                &nbsp;
                <strong>General Data Protection Regulation (GDPR) law</strong>.
                The team of technicians and developers at Sticky Tasks work real
                hard to make sure that every data subject using the software is
                protected by every means. In addition to the implemented
                strategies to meet GDPR and other standard privacy requirements,
                we also make frequent updates to our Privacy Policy while
                staying up-to-date with recent security advancements in the
                market.
              </p>

              <p>
                If you have any concerns related GDPR compliance, feel free to
                reach out at{" "}
                <a href="mailto:info@stickytasks.com">info@stickytasks.com</a>.
              </p>

              <h1 class="entry-title" id="changes-to-privacy-policy">
                Changes to Privacy Policy
              </h1>

              <p>
                As mentioned above, privacy law for every software evolves
                frequently, therefore we might make changes to our privacy
                policy accordingly. In case we make any change to the policy,
                then we will surely notify users on our main website and social
                media handles. Additionally, there will be plenty of informative
                material available on the website to keep users in the loop.
              </p>

              <h1 class="entry-title" id="enforcement">
                Enforcement
              </h1>

              <p>
                Sticky Tasks implements a self-assessment approach to ensure
                compliance with our Privacy Policy. Moreover, we consistently
                verify that our Privacy Policy is comprehensive and accurate,
                covering information that should be displayed, accessible and in
                line with the EU GDPR guidelines.
              </p>

              <p>
                For any additional inquiries regarding Privacy Policy at Sticky
                Tasks, do not hesitate to reach out.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PrivacyPolicy;
