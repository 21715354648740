import React from "react";
import {Helmet} from "react-helmet";
import HeroSection from "../Home/HeroSection";
import WhyST from "../Home/WhyST";
import AccordionW from "../Home/Accordion";
import Single from "../Home/Single";
import Integration from "../Universal/IntegrationsAnim";
import ST4Cols from "../Home/ST4Cols";
import PricingSnippet from "../Universal/PricingSnippet";
import ThreeStep from "../Home/ThreeStep";
import UseCases from "../Home/UseCases";
import CaseStudies from "../Home/CaseStudies";
import Resources from "../Home/Resources";

import SingleImg from "../../assets/imgs/mainpage/7.png";

//resources
function Home() {
  const priceCard = {
    heading: "Turbocharge your productivity now with our free plan.",
    desc: "No upfront fee, hidden charges, or cost overrun. Do more with less while sticking to your budget. Literally.    ",
  };

  const singleCol = {
    heading: "Teamwork is a lot easier when everything is in one space",
    img: SingleImg,
    text: "Direct multiple projects simultaneously while removing bottlenecks that drag out work.",
  };
  return (
    <div>
      <Helmet>
            <title>StickyTasks: Your One-Stop Shop for Task Management</title>
            <meta name="description" content="StickyTasks: An easy-to-use task management tool for teams and individuals." />
      </Helmet>
      <HeroSection />
      <Single
        heading={singleCol.heading}
        image={singleCol.img}
        text={singleCol.text}
      />
      <ThreeStep />
      <AccordionW />
      <Integration />
      <ST4Cols />
      <PricingSnippet heading={priceCard.heading} desc={priceCard.desc} />
      <WhyST />
      <UseCases />
      <CaseStudies />
      <Resources />
    </div>
  );
}

export default Home;
