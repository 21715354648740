import React from "react";
import Featuress from "../../Features/Features";
import PricingSnippet from "../../Universal/PricingSnippet";



import VirtualAttendance from '../../../assets/imgs/Features/AttendMan/VirtualAttend.png';
import DetailAttedRecord from '../../../assets/imgs/Features/AttendMan/AM-DetailedAttendanceRecord.png';
import TeamAttendBanner from '../../../assets/imgs/Features/AttendMan/AM-Maindashboard.png';

import QA from '../../../assets/imgs/Features/AttendMan/AM-QuickAccess.png';


function AttendanceM() {
  const priceCard = {
    heading: "Turbocharge your productivity now with our free plan.",
    desc: "No upfront fee, hidden charges, or cost overrun. Do more with less while sticking to your budget. Literally.    ",
  };

  var head = {
    title: `Automate Time Collection
    `,
    desc: `Empower your workforce with high-end and cost-effective alternative to traditional punch clocks. StickyTasks is functional on any computer or device with an internet connection.
    `,
    img: TeamAttendBanner,
  };

  var sections = [
    {
      img: VirtualAttendance,
      title: `Virtual Attendance
      `,
      desc: `Switch to one of the most modernized attendance management tool, StickyTasks that enables remote employees to check-in on the go.
      `,
    },
    {
      img: DetailAttedRecord,
      title: `Detailed Attendance Record
      `,
      desc: `Save time, streamline processes, and supercharge your business operations with StickyTasks detailed timesheets. Multiple valuable reports will help managers gain full transparency.
      `,
    },
    {
      img: QA,
      title: `Quick Access
      `,
      desc: `With its interactive design and unique controls, StickyTasks make an advanced attendance tracking tool that employees enjoy using.
      `,
    },
  ];

  return (
    <div>
      <Featuress headerData={head} sectionData={sections} />
      <PricingSnippet heading={priceCard.heading} desc={priceCard.desc} />
    </div>
  );
}

export default AttendanceM;
