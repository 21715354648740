import React from "react";
import {Helmet} from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";

function Terms() {
  return (
    <div>
      <Helmet>
            <title>StickyTasks Terms and Conditions: Review Our Service Agreement</title>
            <meta name="description" content="Review our terms and conditions before using StickyTasks to ensure you understand our policies." />
      </Helmet>
      <Container fluid className="banner-container">
        <Row>
          <Col>
            <h1 class="entry-title" itemprop="headline">
              Terms and Conditions
            </h1>
            <div class="entry-content clear" itemprop="text">
              <p>
                Sticky Tasks is a top-performing work management platform
                offering powerful team collaboration and organizational tools.
                To get started with StickyTasks, the Terms and Conditions
                mentioned in this document associates a legal agreement between
                the user and our platform, explaining the guidelines governing
                the use of Sticky Tasks services and features.
              </p>

              <p>
                By using or signing-up at Sticky Tasks, the user acknowledges
                and agrees to all the Terms and Conditions mentioned. It is
                advised to read the document for a thorough understanding of all
                aspects before registering an account on the website.
                Additionally, we might modify these Terms and Conditions from
                time to time, and you are recommended to monitor this page for
                the latest updates.
              </p>

              <p>
                <strong>Table of Contents:</strong>
              </p>

              <ul>
                <li>Account Registration</li>
                <li>Restrictions</li>
                <li>Payment Protocols</li>
                <li>User Content</li>
                <li>Copyright Notice</li>
                <li>Terms for termination</li>
                <li>General Provisions</li>
              </ul>

              <h1 class="entry-title" id="account-registration">
                Account Registration
              </h1>

              <p>
                You need to register on the website with a username and password
                to get complete access to our services. Please provide your
                accurate, up-to-date information with existing registration
                details, and make sure your credentials remain safe and
                confidential. Once registered, you agree to be held responsible
                for all activities under your username. By completing the
                registration process, you confirm your usage through the
                provided details. By agreeing to our account registration
                process, you represent and warrant the authority of making
                decisions. Therefore, we are entitled to rely on the
                instructions provided in your account.
              </p>

              <h1 class="entry-title" id="restrictions">
                Restrictions
              </h1>

              <p>
                The customer is responsible for not himself or permits others to
                reverse engineer, decode, decompile, disassemble, discover the
                source code, flip algorithms of the services, object codes,
                hardware tokens, underlying structures, and extract data from
                the site. You are not entitled to modify, create derivative
                works, translate, rent, sublicense, share, distribute, loan,
                lease, or use anything from the website as it is the property of
                this business. Any attempt to gain unauthorized access to any
                restricted aspects of the services is strictly prohibited and in
                case of an attempt, the customer is held accountable. Prior
                written consent is required in case of the use of data
                applicable by the law.
              </p>

              <h1 class="entry-title" id="payment">
                Payment
              </h1>

              <p>
                The customer has to pay fees specified in the Order Forms along
                with the applicable sales tax, usage, and other purchase related
                excises. In case of exemption, a valid certification is required
                to justify immunity from taxes, usages, and sales. The customer
                is completely responsible for taxes related to Services and the
                Agreement, exclusive of taxes on the platform’s income. Please
                be noted that all the fees and expenses are going to be
                transacted in U.S. dollars.
              </p>

              <h1 class="entry-title" id="user-content">
                User Content
              </h1>

              <p>
                By agreeing to our Terms, the user agrees that the entire data
                such as text, photographs, sound, graphics, software, video, or
                any kind of material posted, submitted, or displayed by them on
                the platform is their sole responsibility. Sticky Tasks does not
                claim any control or ownership over user content. However, the
                content is stored on our dedicated servers for a specific
                time-period as adherence to regulatory reforms.
              </p>

              <h1 class="entry-title" id="copyright-notice">
                Copyright Notice
              </h1>

              <p>
                The content the user uploads on the platform must ensure
                compliance with US copyrights and other legislations. As
                mentioned earlier, we do not claim any intellectual property
                rights over the material the user provides to the Service. By
                selecting the option of sharing pages publicly, the user agrees
                to enable others to share and view their content.
              </p>

              <p>
                While we do not pre-screen user content, Sticky Tasks and its
                administrators have complete rights (zero obligation) with sole
                discretion for refusing or removing content that is not
                appropriate or does not align with civil, state, or regional
                parameters.
              </p>

              <h1 class="entry-title" id="terms-for-termination">
                Terms for Termination
              </h1>

              <p>
                Users are free to delete their account anytime they want by
                uninstalling our desktop application, or unsubscribing from any
                of our services.
              </p>

              <p>
                Additionally, if we detect any form of violation of our Terms
                and Conditions, we have the right to suspend or block your
                account. However, we do not have the right to delete the user’s
                account, and this is up to them to liaise and resolve the issue.
              </p>

              <h1 class="entry-title" id="general-provisions">
                General Provisions
              </h1>

              <ul>
                <li>
                  User agrees not to duplicate, reproduce, sell, or exploit any
                  segment of the service, or access our service without express
                  written consent by Sticky Tasks.
                </li>
              </ul>

              <ul>
                <li>
                  We have the right, but have no obligation to, remove user
                  accounts and content that we determine as{" "}
                  <strong>
                    offensive, unlawful, libelous, threatening, obscene,
                    defamatory,
                  </strong>{" "}
                  or otherwise intolerable or violates our Terms of service and
                  intellectual property.
                </li>
                <li>
                  Physical, verbal, written or other abuse (including
                  retribution) of any Sticky Tasks client, member, employee, or
                  staff will result in your account termination.
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Terms;
