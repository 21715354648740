import React from "react";
import Featuress from "../../Features/Features";
import PricingSnippet from "../../Universal/PricingSnippet";

import FeatureBanner from "../../../assets/imgs/FeatureBanner.png";
import PlaningImg from "../../../assets/imgs/planning image.png";
import accomodate from "../../../assets/imgs/accomodate.png";


import ToDoBan from '../../../assets/imgs/ToDoBanner.png';
import TrackItAll from '../../../assets/imgs/TrackItAll.png';
import DefineTarget from '../../../assets/imgs/DefineTraget.png';

import StreamLine from '../../../assets/imgs/SandP.png';


function TodoL() {
  const priceCard = {
    heading: "Turbocharge your productivity now with our free plan.",
    desc: "No upfront fee, hidden charges, or cost overrun. Do more with less while sticking to your budget. Literally.    ",
  };

  var head = {
    title: `Capture Every Task & Deliver
    `,
    desc: `Convert your idea into a functional plan with StickyTasks. Its highly distinguished to do list feature assist users to strategize, coordinate, and execute processes smoothly.
    `,
    img: ToDoBan,
  };

  var sections = [
    {
      img: DefineTarget,
      title: `Define & Achieve Targets
      `,
      desc: `With cloud-based management StickyTask helps user achieve new productivity peaks. Keep your planners synced in around-the-clock to boost efficiency. Accomplish it all with StickyTasks!
      `,
    },
    {
      img: TrackItAll,
      title: `Track It All
      `,
      desc: `StickyTaks makes it easier for managers to keep a track of everything their team is working on. Moreover, the team can update assigned daily or weekly standups instantly.
      `,
    },
    {
      img: StreamLine,
      title: `Streamline & Prioritize
      `,
      desc: `Maintain an effective organizational flow using to-do-list feature of StickyTasks. Determine and prioritize the urgency of each task with time estimates, deadlines, and labels.
      `,
    },
  ];

  return (
    <div>
      <Featuress headerData={head} sectionData={sections} />
      <PricingSnippet heading={priceCard.heading} desc={priceCard.desc} />
    </div>
  );
}

export default TodoL;
