import React from "react";
import logo1 from "../../assets/clients/softception.svg";
import logo2 from "../../assets/clients/salsoft.svg";
import logo3 from "../../assets/clients/hpp.svg";
import logo4 from "../../assets/clients/mc.svg";

const LogoList = () => {
    const logoCtnStyle={
        display:"flex",
        alignItems:"center",
        justifyContent: "center",
        width:"100%",
    };
  
    const imgStyle=
    {
      width: "120px",
      marginRight: "30px"
    };
  
  return (
    <div className="mob-dis-none">
      <div style={logoCtnStyle} className="logolist-ctn ">
        <div>
          <img src={logo1} style={imgStyle} />
        </div>
        <div >
          <img src={logo2} style={imgStyle} />
        </div>
        <div>
          <img src={logo3} style={imgStyle} />
        </div>
        <div>
          <img src={logo4} style={imgStyle} />
        </div>
      </div>
    </div>
  );
};

export default LogoList;
