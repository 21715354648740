import React from "react";
import {Helmet} from "react-helmet";
import PricingSnippet from "../../UseCases/PricingSnippetUC";
import { Container, Row, Col } from "react-bootstrap";

function Softception() {
  const priceCard = {
    heading: "Time to Try StickyTasks",
    desc: "",
    ctaText: "Sign up for a free trial today!",
  };
  return (
    <div>
      <Helmet>
            <title>StickyTasks Case Study: HPP</title>
            <meta name="description" content="Read about how HPP implemented StickyTasks to improve their team's workflow and productivity." />
      </Helmet>
      <Container fluid className="casestudy-hero features-ctn">
        <Row>
          <Col>
            <h2 className="page-heading">Half Price Packaging</h2>
            <p className="page-description mt-2">
              Half Price Packaging, a US based company, caters to customized
              design and packaging needs of businesses of all sizes. They focus
              on providing top-of-the-line packaging options for various
              products and the most innovative styled boxes and offer variable
              quantities and market competitive pricing for all customers.
            </p>
          </Col>
        </Row>
      </Container>
      <Container fluid className="feature-container">
        <Row>
          <Col md={2}></Col>
          <Col md={8}>
            <h1 class="entry-title">Problems They Were Looking to Solve</h1>

            <p className="section-description">
              Being a packaging company, Half Price Packaging had several
              departments to supervise and manage. As a team grows the need for
              tools to increase transparency and efficiency also grows. Half
              Price Packaging has been focusing on providing the best designs
              and quality of packaging, however, their creativity was lacking as
              most managers were always busy in monitoring the team instead of
              spending time on new and better strategies for the business.
              <br></br>
              Another major problem was task management and communication among
              teams. With half the employees at the factory and the other half
              at the main office, Half Price Packaging was unable to keep a
              track of who was working on what and what the final output of each
              team was.
            </p>
            <br />
            <br />
            <h1 class="entry-title">Why StickyTasks</h1>
            <p className="section-description">
              After evaluating a few collaboration tools Half Price Packaging
              realized that StickyTasks is the best suited one for the type of
              business they were running. With employees from all sorts of
              educational backgrounds, Half Price Packaging needed a platform
              where every employee could be evaluated and treated equally.
              <br />
              Starting from the attendance management till the employee
              monitoring feature, Half Price Packaging benefited from everything
              StickyTasks has to offer to make a company more organized,
              productive and well managed.
              <br />
              Along with easy management they were also able to complete their
              tasks more efficiently through easy to-do lists and a seamless
              reporting system, allowing managers to focus more on the company’s
              growth than monitoring and management related tasks.
            </p>
          </Col>
          <Col md={2}></Col>
        </Row>
      </Container>
      <PricingSnippet
        heading={priceCard.heading}
        desc={priceCard.desc}
        ctaText={priceCard.ctaText}
      />
    </div>
  );
}

export default Softception;
