import React from "react";
import { Container, Navbar, Nav, NavDropdown, Row, Col } from "react-bootstrap";
import Logo from "../../assets/imgs/stlogo.png";
import { LinkContainer } from "react-router-bootstrap";
import pmLogo from "../../assets/icons/project-management.svg";
import tmLogo from "../../assets/icons/tasks-management.svg";
import ptLogo from "../../assets/icons/productivity-tracking.svg";
import atLogo from "../../assets/icons/application-tracking.svg";
import temLogo from "../../assets/icons/teams-management.svg";
import amLogo from "../../assets/icons/attendance-management.svg";
import emLogo from "../../assets/icons/employee-monitoring.svg";
import pLogo from "../../assets/icons/payroll.svg";
import tdLogo from "../../assets/icons/todo-list.svg";
import rLogo from "../../assets/icons/reports.svg";
import iLogo from "../../assets/icons/integrations.svg";


import remotework from "../../assets/imgs/remotework.png";
import stickytask from "../../assets/imgs/stickytask.png";
import softwareeng from "../../assets/imgs/softwareeng.png";
import smallbusiness from "../../assets/imgs/smallbusiness.png";
import mag from "../../assets/imgs/marketing&growth.png";

function Header() {
  const logoStyle = {
    width: "120px",
    marginTop: "5px",
  };

  return (
    <div>
      <Navbar
        expand="lg"
        style={{
          paddingBottom: "15px",
          paddingTop: "0px",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
        className="stickytasks-nav"
      >
        <Container style={{ paddingLeft: "0px", paddingRight: "0px" }}>
          <LinkContainer to="/">
            <Navbar.Brand>
              <img src={Logo} style={logoStyle} />
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="mt-3">
            <Nav className="me-auto">
              <LinkContainer to="/">
                <Nav.Link>Home</Nav.Link>
              </LinkContainer>
              <NavDropdown
                title="Features"
                className="features-dropdown"
                id="basic-nav-dropdown"
              >
                <Row>
                  <Col md={4} sm={12}>
                    <NavDropdown.Header>Project Management</NavDropdown.Header>
                    <LinkContainer to="/features/project-management">
                      <Nav.Link>
                        <div className="dropdown-item-container">
                          <div className="image-area">
                            <img src={pmLogo} />
                          </div>
                          <div className="text-area">
                            <h6>Project Management</h6>
                            <p>Track your team from beginning to end</p>
                          </div>
                        </div>
                      </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/features/tasks-management">
                      <Nav.Link>
                        <div className="dropdown-item-container">
                          <div className="image-area">
                            <img src={tmLogo} />
                          </div>
                          <div className="text-area">
                            <h6>Tasks Management</h6>
                            <p>Capture and organize tasks in seconds</p>
                          </div>
                        </div>
                      </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/features/productivity-tracking">
                      <Nav.Link>
                        <div className="dropdown-item-container">
                          <div className="image-area">
                            <img src={ptLogo} />
                          </div>
                          <div className="text-area">
                            <h6>Productivity Tracking</h6>
                            <p>Track productive and non-productive hours</p>
                          </div>
                        </div>
                      </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/features/application-tracking">
                      <Nav.Link>
                        <div className="dropdown-item-container">
                          <div className="image-area">
                            <img src={atLogo} />
                          </div>
                          <div className="text-area">
                            <h6>Application Tracking</h6>
                            <p>Track program and application usage</p>
                          </div>
                        </div>
                      </Nav.Link>
                    </LinkContainer>
                  </Col>
                  <Col md={4} sm={12}>
                    <NavDropdown.Header>Team Management</NavDropdown.Header>
                    <LinkContainer to="/features/team-management">
                      <Nav.Link>
                        <div className="dropdown-item-container">
                          <div className="image-area">
                            <img src={temLogo} />
                          </div>
                          <div className="text-area">
                            <h6>Team Management</h6>
                            <p>Track everything to meet deadlines</p>
                          </div>
                        </div>
                      </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/features/attendance-management">
                      <Nav.Link>
                        <div className="dropdown-item-container">
                          <div className="image-area">
                            <img src={amLogo} />
                          </div>
                          <div className="text-area">
                            <h6>Attendance Management</h6>
                            <p>Manage attendance anytime, anywhere</p>
                          </div>
                        </div>
                      </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/features/employee-monitoring">
                      <Nav.Link>
                        <div className="dropdown-item-container">
                          <div className="image-area">
                            <img src={emLogo} />
                          </div>
                          <div className="text-area">
                            <h6>Employee Monitoring</h6>
                            <p>Track & monitor employees screens</p>
                          </div>
                        </div>
                      </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/features/payroll">
                      <Nav.Link>
                        <div className="dropdown-item-container">
                          <div className="image-area">
                            <img src={pLogo} />
                          </div>
                          <div className="text-area">
                            <h6>Payroll</h6>
                            <p>Pay employees according worked hours</p>
                          </div>
                        </div>
                      </Nav.Link>
                    </LinkContainer>
                  </Col>
                  <Col md={4} sm={12}>
                    <NavDropdown.Header>More Features</NavDropdown.Header>
                    <LinkContainer to="/features/todo-list">
                      <Nav.Link>
                        <div className="dropdown-item-container">
                          <div className="image-area">
                            <img src={tdLogo} />
                          </div>
                          <div className="text-area">
                            <h6>To Do List</h6>
                            <p>Powerful, Insightful, and Incredibly</p>
                          </div>
                        </div>
                      </Nav.Link>
                    </LinkContainer>
                    {/* <LinkContainer to="/features/reports">
                      <Nav.Link>
                        <div className="dropdown-item-container">
                          <div className="image-area">
                            <img src={rLogo} />
                          </div>
                          <div className="text-area">
                            <h6>Built-in BI & Reports</h6>
                            <p>Visualized data in BI & dynamic reports</p>
                          </div>
                        </div>
                      </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/features/integrations">
                      <Nav.Link>
                        <div className="dropdown-item-container">
                          <div className="image-area">
                            <img src={iLogo} />
                          </div>
                          <div className="text-area">
                            <h6>Integrations</h6>
                            <p>Integrate with others software too</p>
                          </div>
                        </div>
                      </Nav.Link>
                    </LinkContainer> */}
                  </Col>
                </Row>
              </NavDropdown>
              <NavDropdown
                title="Use Cases "
                id="basic-nav-dropdown"
                className="cases-dropdown"
              >
                <Row>
                  <Col md={12}>
                    <LinkContainer to="/cases/remote-work">
                      <Nav.Link>
                        <div className="dropdown-item-container">
                          <div className="image-area image-area-uc">
                            <img src={remotework} />
                          </div>
                          <div className="text-area text-area-uc">
                            <h6>Remote Work/Teams</h6>
                          </div>
                        </div>
                      </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/cases/designers">
                      <Nav.Link>
                        <div className="dropdown-item-container">
                          <div className="image-area image-area-uc">
                            <img src={stickytask} />
                          </div>
                          <div className="text-area text-area-uc">
                            <h6>Designers</h6>
                          </div>
                        </div>
                      </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/cases/software-engineers">
                      <Nav.Link>
                        <div className="dropdown-item-container">
                          <div className="image-area image-area-uc">
                            <img src={softwareeng} />
                          </div>
                          <div className="text-area text-area-uc">
                            <h6>Software Engineers</h6>
                          </div>
                        </div>
                      </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/cases/smb">
                      <Nav.Link>
                        <div className="dropdown-item-container">
                          <div className="image-area image-area-uc">
                            <img src={smallbusiness} />
                          </div>
                          <div className="text-area text-area-uc">
                            <h6>Small Businesses</h6>
                          </div>
                        </div>
                      </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/cases/marketing">
                      <Nav.Link>
                        <div className="dropdown-item-container">
                          <div className="image-area image-area-uc">
                            <img src={mag} />
                          </div>
                          <div className="text-area text-area-uc">
                            <h6>Marketing & Growth</h6>
                          </div>
                        </div>
                      </Nav.Link>
                    </LinkContainer>
                  </Col>
                </Row>
              </NavDropdown>
              <LinkContainer to="/pricing">
                <Nav.Link>Pricing</Nav.Link>
              </LinkContainer>

              <NavDropdown title="Resources" id="basic-nav-dropdown">
                <LinkContainer to="/blog">
                  <NavDropdown.Item>Blog</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/case-studies">
                  <NavDropdown.Item>Case Study</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/help">
                  <NavDropdown.Item>Help Center</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
            </Nav>
            <div className="d-flex">
              <Nav className="me-auto">
                <a
                  href="https://app.stickytasks.com"
                  target="_blank"
                  className="nav-link"
                >
                  Login
                </a>
              </Nav>
              <div className="separator"></div>

              <a
                className="cta-btn"
                style={{
                  margin: "0",
                  marginTop: "0px",
                  marginRight:"12px",
                  textDecoration: "none",
                }}
                href="https://app.stickytasks.com/register"
                target="_blank"
              >
                Sign up Free 
              </a>
              <a
                className="cta-btn"
                style={{
                  margin: "0",
                  marginTop: "0px",
                  textDecoration: "none",
                }}
                href="https://app.stickytasks.com/register"
                target="_blank"
              >
                Get a Demo
              </a>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;
