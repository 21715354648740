import React from "react";
import {Helmet} from "react-helmet";
import PricingSnippet from "../../UseCases/PricingSnippetUC";
import { Container, Row, Col } from "react-bootstrap";

function Salsoft() {
  const priceCard = {
    heading: "Time to Try StickyTasks",
    desc: "",
    ctaText: "Sign up for a free trial today!",
  };

  return (
    <div>
      <Helmet>
            <title>StickyTasks Case Study: Salsoft</title>
            <meta name="description" content="Learn how Salsoft successfully implemented StickyTasks to improve their team's workflow and productivity." />
      </Helmet>
      <Container fluid className="casestudy-hero  features-ctn">
        <Row>
          <Col>
            <h2 className="page-heading">Salsoft</h2>
            <p className="page-description mt-2">
              An Addition to StickyTasks' List of Satisfied Clients
            </p>
          </Col>
        </Row>
      </Container>
      <Container fluid className="feature-container">
        <Row>
          <Col md={2}></Col>
          <Col md={8}>
            <p className="section-description">
              An Addition to StickyTasks' List of Satisfied Clients
            </p>
            <br />
            <br />
            <h1 class="entry-title">How did StickyTasks help Salsoft?</h1>

            <p className="section-description">
              With a workforce of over 1000 employees spread in two different
              cities and working different shifts, Salsoft was facing several
              challenges in the collaboration of teams as well as tracking the
              productivity of individuals.This is when they decided to sign up
              for an all in one tool to manage a team so big.
            </p>
            <br />
            <br />
            <h1 class="entry-title">Attendance Tracking</h1>

            <p className="section-description">
              With the growing demand of hybrid working conditions, it becomes
              extremely difficult to track the availability and attendance of
              employees. With an easy sign up and monitoring feature Salsoft was
              able to empower its workforce with a high-end and cost-effective
              alternative to traditional punch clocks.
            </p>
            <br />
            <br />
            <h1 class="entry-title">An organized workflow</h1>

            <p className="section-description">
              With highly distinguished to-do lists and productivity tracking
              features managers have been able to maintain internal
              communication with complete transparency and evaluate as well as
              manage their teams with ease, regardless of what time it is or
              where a resource is working from.
            </p>
            <br />
            <br />
            <h1 class="entry-title">Easy reporting and payroll management</h1>

            <p className="section-description">
              No matter the size of the enterprise, every organization requires
              a built-in BI software to increase operational efficiency. Another
              challenge most companies face is reporting and payroll management
              systems; StickyTasks is one of the few tools that apart from
              allowing integration of other communication applications also
              allows managers to oversee reporting and manage payroll systems.
            </p>
            <br />
            <br />
            <h1 class="entry-title">Results</h1>

            <p className="section-description">
              Ever since Salsoft has signed up with StickyTasks they have been
              able to work more efficiently and without being restricted to any
              time or location. Employees tend to stay more focused as they are
              being observed and managers are now finding it easier to guide
              their team and help them grow with ease.
            </p>
          </Col>
          <Col md={2}></Col>
        </Row>
      </Container>
      <PricingSnippet
        heading={priceCard.heading}
        desc={priceCard.desc}
        ctaText={priceCard.ctaText}
      />
    </div>
  );
}

export default Salsoft;
