import React from "react";
import {Helmet} from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";
import CasesList from "../Cases/CasesList";

function Cases() {
  const ctnStyle = {
    textAlign: "center",
    paddingTop: "4rem",
    paddingBottom: "4rem",
  };
  return (
    <div>
      <Helmet>
            <title>StickyTasks Case Studies: See How We've Helped Teams Succeed</title>
            <meta name="description" content="Read about how other businesses have successfully implemented StickyTasks to streamline their workflow." />
      </Helmet>
      <Container fluid style={ctnStyle} className="Cases-ctn">
        <h2
        
          className="blog-mainpage-heading"
        >
          Case Studies
        </h2>
        <p
          className="blog-mainpage-description"
        >
          Plan, organize, and collaborate on any project with powerful task
          management features that can be customized according to project needs
          and specifications.
        </p>
      </Container>
      <CasesList />
    </div>
  );
}

export default Cases;
