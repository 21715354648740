import React from "react";
import { Container } from "react-bootstrap";
import lottie from "lottie-web";
import Integration from "../../assets/lottie/workswith.json";

const IntegrationAnimUC = (props) => {

  React.useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#workswith-anim"),
      animationData: Integration,
      renderer: "svg",
      loop: true,
      autoplay: true,
    });
  }, []);

  const ctnStyle = {
    textAlign: "center",
    marginTop: "30px",
   
   
  };
  const animStyle = {
    width: "750px"
  };

  const AnimTextStyle = {
    width: "60%",
    margin: "auto"


  
  };

  const animParent = {
    width: "100%",
    display: "flex",
    justifyContent: "center"
  };
  return (
    <div>
      <Container fluid style={ctnStyle} className="normal-container col-12">
        <h2 className="section-heading">

          {props.headingText}
        </h2>
        <div style={animParent}>
          <div style={animStyle} id="workswith-anim"></div>
        </div>
        <p style={AnimTextStyle} className="section-description">
          {props.desc}
        </p>
      </Container>
    </div>
  );
};

export default IntegrationAnimUC;
