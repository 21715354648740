import React from "react";
import { Row, Container, Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import facebook from "../../assets/imgs/facebook.png";
import insta from "../../assets/imgs/insta.png";
import twitter from "../../assets/imgs/tweeter.png";
import youtube from "../../assets/imgs/yt.png";
import './Bottom.css';






function BottomBar() {
  const footerCtnStyle = {
    backgroundColor: "var(--black)",
    height: "90%",
    padding: "0.8rem 0rem ",
  };



  const socialIcons = {
    width: "18px",
    marginLeft: "10px",
  };
  const ytIcon = {
    width: "22px",
    marginLeft: "10px",
  };







  return (
    <div style={footerCtnStyle}>


      <div >
        <Container >


          <Row className="rowStyle">

            <Col md={6}>
              <div className="right-ctn footerBottomStyle">
                <div>
                  <p className="m-0 navLinkStyle">
                    &copy;StickyTasks 2022
                  </p>

                </div>
                <div className="footer-bottom-ctn">
                  <ul className="fbottom-links">
                    <li><Link to="/privacy-policy/">Privacy Policy</Link></li>
                    <li><Link to="/terms-and-conditions/">Terms & Conditions</Link></li>
                  </ul>

                </div>
              </div>
            </Col>

            <Col md={6}>


              <ul className="m-0 socilaUlStyle">
                <li>
                  <a href="https://www.facebook.com/stickytasks" target="_blank">
                    <img src={facebook} style={socialIcons} />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/stickytasks/" target="_blank" >
                    <img src={insta} style={socialIcons} />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/sticky_tasks" target="_blank">
                    <img src={twitter} style={socialIcons} />
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/channel/UCXJ64BlzA7k486TRFm6jwgQ" target="_blank">
                    <img src={youtube} style={ytIcon} />
                  </a>
                </li>
              </ul>


            </Col>
          </Row>


        </Container>

      </div>

    </div>
  );
}

export default BottomBar;
