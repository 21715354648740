import React from 'react';
import { Container, Row, Col } from "react-bootstrap";

import HowCanAssign from "../../../assets/imgs/helpcenter/HowCanAssign/HowcanAssign.png";
import { Link } from "react-router-dom";



export const HowCanAssignTask = () => {

    const width = {
        width: "100%",
    }

    const padd={
        paddingTop:"8rem"
    }
    return (
        <Container style={padd}>

            <h2>How Can I Assign Tasks to My Teammates?
            </h2>

            <p>When there is a lot to be done, having confusion and lack of clarity across teams is quite a normal outcome. The best way to improve efficiency and collaboration within a team, is to assign responsibilities!
            </p>


            <p>Assigning tasks to your teammates ensures that everyone knows what their roles are. StickyTasks manager can monitor employee activity, what they are working on related tasks so they can collaborate to crush the team's goals together!</p>


            <h3>How can I assign tasks?
            </h3>

            <p>Assign tasks on your board to anyone in your account using the People Column!
            </p>

            <p>Let's add it to our board!</p>

            <p>These are some of the basic steps that involve assigning tasks to your teammates in an efficient manner.
            </p>

            <ul>

                <li>

                    Go to the menu bar and click on “My Tasks”.

                </li>


                <li>There the drop-down with Add Tasks will occur, from there you can easily add tasks and assign them to the relevant resource.
                </li>

                <li>You can assign multiple people and even entire teams.
                </li>

                <li>When a task is assigned, the teammate it was assigned to will receive a bell notification so they always stay up to date with their responsibilities!
                </li>

                <li>Users can also choose to receive e-mail notifications.
                </li>
            </ul>
            <img src={HowCanAssign} alt="" srcset="" style={width} />


            <h3>Can I assign tasks to users who are not in my account?
            </h3>

            <p>Tagging team members or labeling them for tasks only works for users in your account. To assign tasks to team members who are not in your account, you can either invite them to join your account.
            </p>

            <p>Type the assignee's name in the cell in the Text Column so that everyone on your team knows who's responsible for that task!
            </p>

            <p>To learn more about inviting guest to your workspace <a href="https://docs.google.com/document/d/1S8VxlJKdvr5jykNAR-H2dXGbiIxAkADK/edit?usp=sharing&ouid=105931174921621543929&rtpof=true&sd=true">check out FAQs</a></p>


            <p>If you have any questions, please reach out to our team on <a href='mailto:help@stickytasks.com'> help@stickytasks.com</a>.
            </p>


        </Container>
    )
}

export default HowCanAssignTask;
