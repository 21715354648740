import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import playbutton from "../../assets/imgs/playbutton.svg";
import banner from "../../assets/imgs/banner.svg";
import LogoList from "./LogoList";
import ReactDOM from "react-dom";
import Typewriter from "typewriter-effect";
import ModalVideo from "react-modal-video";
import 'react-modal-video/scss/modal-video.scss';
import './HeroSection.css';


const HeroSection = () => {
  const app = document.getElementById("app");
  const [isOpen, setOpen] = useState(false);
  const typewriter = new Typewriter(app, {
    loop: true,
    delay: 75,
  });
  return (
    <Container fluid md={12} sm={6} className=" banner-container ">
      <Row className="herosection-ctn">
        <Col>
          <h1 className="banner-heading">
            Collaboration <br></br> Platform for
          </h1>
          <h1 className="brand-heading">
            <Typewriter
              options={{
                strings: ['Remote Teams.', 'Software Engineers.', 'Marketing Agencies.', 'Small Business.'],
                autoStart: true,
                loop: true,
              }}
            />
          </h1>

          <p className="banner-description mt-4 descStyle">
            Stay in the loop with your managers and team via interactive
            automation and customizable workflows that drive strong business
            impact.
          </p>
<div className="btn-ctn">
          <a className="cta-btn"  href="https://app.stickytasks.com/register"
                target="_blank">Get a Demo</a>
          <ModalVideo
            channel="youtube"
            autoplay
            isOpen={isOpen}
            videoId="YBrreLKNz6c"
            onClose={() => setOpen(false)}
          />

          <button className='VideoBtnStyle' onClick={() => setOpen(true)}>
            Watch Video <img src={playbutton} />
          </button>



          </div>

          <p className="purple-heading mt-3 mob-dis-none">TRUSTED BY TEAMS AT</p>
          <LogoList />
        </Col>
        <Col className="text-center">
          <img src={banner} className='bannerImgStyle' />
        </Col>
      </Row>
    </Container>
  );
};

export default HeroSection;
