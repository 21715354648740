import React from "react";
import { Container } from "react-bootstrap";
import lottie from "lottie-web";
import Integration from "../../assets/lottie/workswith.json";
import './Integration.css';

function IntegrationAnim() {
  React.useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#workswith-anim"),
      animationData: Integration,
      renderer: "svg",
      loop: true,
      autoplay: true,
    });
  }, []);

  const ctnStyle = {
    textAlign: "center",
  };
  const animStyle = {
    width: "750px",
  };



  const animParent = {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  };
  return (
    <div>
      <Container fluid style={ctnStyle} className="normal-container col-12">
        <h2 className="section-heading">
          Accelerate team efficiency and productivity with smart integrations
        </h2>
        <div style={animParent}>
          <div style={animStyle} id="workswith-anim"></div>
        </div>
        <p className="section-description textStyle" >
          Connect StickyTasks with familiar apps to automate tasks and projects
          so you can pay attention to what truly matters - your business.
        </p>
      </Container>
    </div>
  );
}

export default IntegrationAnim;
