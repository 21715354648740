import React from "react";

import { Container, Row, Col, Accordion, Card } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";

import Tasks from "../../assets/imgs/mainpage/pm.png";
import Views from "../../assets/imgs/mainpage/tman.png";
import Dashboards from "../../assets/imgs/mainpage/4.png";
import Monitoring from "../../assets/imgs/mainpage/5.png";
import Docs from "../../assets/imgs/mainpage/6.png";
import './accordian.css';

function AccordionW() {
  const headingStyle = {
    marginTop: "2rem",
  };

  const ctnStyle = {
    paddingTop: "4rem",
    paddingBottom: "4rem",
  };

  function toggleImage(key) {
    if (key == null) {
      return;
    }

    if (!document.getElementsByClassName("image-link")) return;
    var imgsToHide = document.getElementsByClassName("image-link");
    for (var i = 0; i < imgsToHide.length; i++) {
      imgsToHide[i].style.opacity = "0";
      imgsToHide[i].style.height = "0";
    }

    const tId = "img-" + key;
    if (!document.getElementById(tId)) return;
    var tElem = document.getElementById(tId);
    tElem.style.opacity = "1";
    tElem.style.height = "auto";
    console.log(tElem);
  }

  function CustomToggle({ children, eventKey, callback }) {
    const { activeEventKey } = React.useContext(AccordionContext);
    //console.log("Active Key: " + activeEventKey);
    toggleImage(activeEventKey);
    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey)
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    //console.log("Eventkey: " + eventKey);
    //console.log("Current Evt Key: " + isCurrentEventKey);
    return (
      <button
        type="button"
        className="custom-button"
        onClick={decoratedOnClick}
        style={{ color: isCurrentEventKey ? "var(--yellow)" : "black" }}
      >
        {children}
      </button>
    );
  }

  // function onAccordionClick({ children, eventKey, callback }) {
  //   const currentEventKey = React.useContext(AccordionContext);
  //   console.log("Active Key: " + currentEventKey.activeEventKey);

  // }

  return (
    <div>
      <Container style={ctnStyle} className="accordion-ctn">
        <Row className="home-accordion">
          <Col md={5} sm={12}>
            <h2 className="section-heading" style={headingStyle}>
              Multiple Features For Every Workflow
            </h2>
            <p className="section-description"></p>
            <Accordion defaultActiveKey="0" flush>
              <Accordion.Item>
                <Card.Header>
                  <CustomToggle eventKey="0">Project Management</CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    Manage every project like a pro using our dynamic project
                    management features that allow users to plan detail-oriented
                    project tasks and timelines, and manage them seamlessly.
                  </Card.Body>
                </Accordion.Collapse>
              </Accordion.Item>

              <Accordion.Item>
                <Card.Header>
                  <CustomToggle eventKey="1">Team Management</CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    Plan, organize, and manage your day-to-day activities with
                    Sticky Task’s highly efficient task management tools that
                    enable work process optimization via task automation.
                  </Card.Body>
                </Accordion.Collapse>
              </Accordion.Item>

              <Accordion.Item>
                <Card.Header>
                  <CustomToggle eventKey="2">
                    Attendance Management & Payroll
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    Utilize our attendance management tools to oversee team
                    attendance in real-time and create highly detailed time
                    sheets to manage employee payroll.
                  </Card.Body>
                </Accordion.Collapse>
              </Accordion.Item>

              <Accordion.Item>
                <Card.Header>
                  <CustomToggle eventKey="3">
                    Team Productivity Monitoring
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    Monitor employee productivity through Sticky Tasks’ various
                    employee monitoring features that allow you to track user’s
                    screen activities and usage of specific programs and
                    applications.
                  </Card.Body>
                </Accordion.Collapse>
              </Accordion.Item>

              <Accordion.Item>
                <Card.Header>
                  <CustomToggle eventKey="4">
                    Built-in BI & Reports
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body>
                    Use Sticky Tasks’ dynamic reporting tools to visualize and
                    record all employee activities, and customize our business
                    reports according to your preferences.
                  </Card.Body>
                </Accordion.Collapse>
              </Accordion.Item>
            </Accordion>
          </Col>
          <Col  sm={12} md={7} style={{ marginTop: "2rem" }}>
            <div className="image-area">
              <img src={Tasks} id="img-0" className="image-link show" />
              <img src={Views} id="img-1" className="image-link" />
              <img src={Dashboards} id="img-2" className="image-link" />
              <img src={Monitoring} id="img-3" className="image-link" />
              <img src={Docs} id="img-4" className="image-link" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AccordionW;
