import React from "react";
import {Helmet} from "react-helmet";
import Featuress from "../../Features/Features";
import PricingSnippet from "../../Universal/PricingSnippet";

import FeatureBanner from "../../../assets/imgs/Features/TeamM/TM-MainDashbaord.png";





import RemScreen from '../../../assets/imgs/Features/TeamM/TM-Remote-Screening-Reporting.png';
import SeamlessCom from '../../../assets/imgs/Features/TeamM/TM-Seamless-Communication.png';

import TaskCord from '../../../assets/imgs/Features/TeamM/TM-Task-Coordination-Allocation.png';

function TeamM() {
  const priceCard = {
    heading: "Turbocharge your productivity now with our free plan.",
    desc: "No upfront fee, hidden charges, or cost overrun. Do more with less while sticking to your budget. Literally.    ",
  };

  var head = {
    title: `Sync In & Communicate with Clarity
    `,
    desc: `Boost your team’s productivity with real-time communication, all-in progress tracking, member availability, and file sharing.
    `,
    img: FeatureBanner,
  };

  var sections = [
    {
      img: TaskCord,
      title: `Task Coordination & Allocation
      `,
      desc: `Manage, prioritize, and execute tasks from conception, budgeting, time tracking, to deployment. Sticky tasks support businesses to level up productivity by streamlining and administering workflows efficiently.
      `,
    },
    {
      img: RemScreen,
      title: `Remote Screening & Reporting
      `,
      desc: `Keep track of every billable minute to ensure on-time and on-budget project delivery. Get everything you need to scale!
      `,
    },
    {
      img: SeamlessCom,
      title: `Seamless Communication
      `,
      desc: `Eliminate unread Slacks, crossed wires, and endless emails. With Sticky Tasks organizations can communicate with teams and clients spontaneously to stay transparent and keep everyone in the loop.
      `,
    },
  ];

  return (
    <div>
      <Helmet>
            <title>Remote Team Management Platform: Improve Collaboration and Efficiency - StickyTasks </title>
            <meta name="description" content="Improve communication and collaboration within your team with StickyTasks' team management tools." />
      </Helmet>
      <Featuress headerData={head} sectionData={sections} />
      <PricingSnippet heading={priceCard.heading} desc={priceCard.desc} />
    </div>
  );
}

export default TeamM;
