import React from "react";
import UseCase from "../../UseCases/UseCase";

import Integration from "../../UseCases/IntegrationsAnimUC";

import CTACard from "../../UseCases/PricingSnippetUC";
import UseCases from "../../UseCases/UseCasesIcons";

import FeatureBanner from "../../../assets/imgs/FeatureBanner.png";



import mag from "../../../assets/imgs/marketing&growth.png";


import ibc from '../../../assets/cases/designer/design1.svg';
import StayInLock from '../../../assets/cases/designer/design2.svg';
import QuantifyProcess from '../../../assets/cases/designer/design3.svg';
import Learn from '../../../assets/cases/designer/design4.svg';
function Designers() {
  var head = {
    title: `Design without worrying about most mundane tasks, yes, we mean it! 
    `,
    desc: `From mind-mapping creative propositions to getting the final touches, we help you get it all done with style. StickyTasks is to design management what Jira is to DevOps: simply game-changing!
    `,
    img: FeatureBanner,
    subheading: `Here are four use cases to illustrate how StickyTasks adds value to a designer’s life
    `,
  };

  var sections = [
    {
      img: ibc,
      title: `1. Ideate but collectively`,
      desc: `Expand your creative goals by building them in a collective using cloud-based management, shared workspaces, and synced planners.`,
    },
    {
      img: StayInLock,
      title: `2. Stay in lockstep with your creative strategy`,
      desc: `Execute To-Do List to supervise requests, revisions, drafts, and cross-functional projects, so you can give your thorough attention to what you do best- crafting iconic designs.`,
    },

    {
      img: QuantifyProcess,
      title: `3. Quantify the process of curation`,
      desc: `Get the foundation for designs right out of the box. Define curation period cycles by tracking billable hours, and prioritize projects by time estimates, deadlines, and labels.`,
    },
    {
      img: Learn,
      title: `4. Learn, unlearn and relearn`,
      desc: `Design critique is design emancipation. StickyTasks and communication channels encourage discussions and feedback for better future design. `,
    },
  ];

  var integrationHeading = {
    heading: `We Are Ready to Play with Your Favorite Design Third-party Apps`,
    desc: "",
  };
  const ctaCard = {
    heading: `Get on to the work you love and leave the mundanity to us; we’ll take care of it!     `,
    desc: ``,
    btnTxt: `Get started with a free account today!`,
  };

  const iconsDataSection = {
    heading: `Some Killer Aspects That Every Designer Admires`,
    subheading: ``,
    icons: [
      {
        heading: 'Share Unlimited Files',
        link: '',
        img: mag
      },
      {
        heading: 'Get feedback in context',
        link: '',
        img: mag
      },
      {
        heading: 'View your version history',
        link: '',
        img: mag
      },
      {
        heading: ' Log in from anywhere',
        link: '',
        img: mag
      },
      {
        heading: ' Invite unlimited reviewers',
        link: '',
        img: mag
      },
      {
        heading: 'Approve files with one click',
        link: '',
        img: mag
      }
    ]
  };

  return (
    <div>
      <UseCase headerData={head} sectionData={sections} />
      <CTACard
        heading={ctaCard.heading}
        desc={ctaCard.desc}
        ctaText={ctaCard.btnTxt}
      />
      <UseCases heading={iconsDataSection.heading} subheading={iconsDataSection.subheading} iconsData={iconsDataSection.icons}/>
      <Integration
        headingText={integrationHeading.heading}
        desc={integrationHeading.desc}
      />
    </div>
  );
}

export default Designers;
