import React from "react";
import UseCase from "../../UseCases/UseCase";

import Integration from "../../UseCases/IntegrationsAnimUC";

import CTACard from "../../UseCases/PricingSnippetUC";
import UseCases from "../../UseCases/UseCasesIcons";

import FeatureBanner from "../../../assets/imgs/FeatureBanner.png";


import mag from "../../../assets/imgs/marketing&growth.png";


import strongfound from '../../../assets/cases/smallbusiness/strongfoundation.svg';
import openwork from '../../../assets/cases/smallbusiness/openwork.svg';
import momentum from '../../../assets/cases/smallbusiness/momentum.svg';
import analytic from '../../../assets/cases/smallbusiness/analytics.svg';

function SMB() {
  var head = {
    title: `Help Your Business Make an Impact with StickyTasks Work Management Suit 
    `,
    desc: `Whether you’re on the brink of being a thriving large business or running full steam ahead from your startup days, StickyTasks helps scale alongside every phase of your business. 
    `,
    img: FeatureBanner,
    subheading: `Here are some key use cases that will illustrate the value StickyTasks brings to your business
    `,
  };

  var sections = [
    {
      img: strongfound,
      title: `1. Build strong foundations for long-term success
      `,
      desc: `Success takes more than just tools. It’s about choosing right people, plays, and practices. And we help you do it all to build the foundations for success that will take you from a small company to a growing business.
      `,
    },
    {
      img: momentum,
      title: `2. Keep up the momentum
      `,
      desc: `Avoid silos early by integrating your teams and SaaS partner, StickyTasks to keep communication transparent, knowledge central, and actions agile.
      `,
    },

    {
      img: openwork,
      title: `3. Build a Culture of Open Work Environment`,
      desc: `With unique social features, employees at every level have a voice to contribute, share, and receive feedback.`,
    },
    {
      img: analytic,
      title: `4. Capitalize on the true power of analytics`,
      desc: `Make data-driven decisions in record speed with StickyTasks Analytics. Use pre-built or customized dashboards to analyze your data across teams and projects.`,
    },
  ];

  var integrationHeading = {
    heading: `Link Up Your Favorite Third-Part App & Save Hours of Trouble
    `,
    desc: "",
  };
  const ctaCard = {
    heading: `Ready To Get Started or Just Have Questions?`,
    desc: `Let us know what you would like to discuss and we’ll be in touch shortly.
    `,
    btnTxt: `Get Started!`,
  };

  const iconsDataSection = {
    heading: `Link Up Your Favorite Third-Part App & Save Hours of Trouble
    `,
    subheading: ``,
    icons: [
      {
        heading: 'Share Unlimited Files',
        link: '',
        img: mag
      },
      {
        heading: 'Get feedback in context',
        link: '',
        img: mag
      },
      {
        heading: 'View your version history',
        link: '',
        img: mag
      },
      {
        heading: ' Log in from anywhere',
        link: '',
        img: mag
      },
      {
        heading: ' Invite unlimited reviewers',
        link: '',
        img: mag
      },
      {
        heading: 'Approve files with one click',
        link: '',
        img: mag
      }
    ]
  };

  return (
    <div>
      <UseCase headerData={head} sectionData={sections} />
      <CTACard
        heading={ctaCard.heading}
        desc={ctaCard.desc}
        ctaText={ctaCard.btnTxt}
      />
      {/* <UseCases heading={iconsDataSection.heading} subheading={iconsDataSection.subheading} iconsData={iconsDataSection.icons}/> */}
      <Integration
        headingText={integrationHeading.heading}
        desc={integrationHeading.desc}
      />
    </div>
  );
}

export default SMB;
