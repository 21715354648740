import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import icon1 from "../../assets/imgs/Group 552.svg";
import icon2 from "../../assets/imgs/Group 555.svg";
import icon3 from "../../assets/imgs/Group 564.svg";
import icon4 from "../../assets/imgs/Group 566.svg";
import './ST4Cols.css';

const StickyTasksYourWay = () => {
  

  const colStyle = {
    padding: "1rem",
    boxShadow: "3px 5px 12px #0000001A",
    borderRadius: "4px",
    
  };

  const imgStyle = {
    transform: "scale(0.6)",
    marginBottom: "1rem",
  };

  const pStyle = {
    marginTop: "1rem",
  };

  return (
    <Container fluid className="col-12 st4cols-ctn" style={{ textAlign: "center" }}>
      <h2 className="section-heading mt-5">StickyTasks Your Way</h2>

      <p className="section-description">Get more done in less time</p>

      <Row className='ST4rowStyle'>
        <Col style={colStyle}>
          <img src={icon1} style={imgStyle} />
          <h5 className="smallcard-heading">76% Performance Improvement</h5>
          <p style={pStyle} className="card-description">
            Streamline collaboration, implement periodic analysis, and track
            your progress with complete visibility to achieve the desired
            results.
          </p>
        </Col>
        <Col style={colStyle}>
          <img src={icon2} style={imgStyle} />
          <h5 className="smallcard-heading">81% Reduced Errors & Rework</h5>
          <p style={pStyle} className="card-description">
            Eliminate communication gaps and provide a single source of truth
            for your team to reduce human error.
          </p>
        </Col>
        <Col style={colStyle}>
          <img src={icon3} style={imgStyle} />
          <h5 className="smallcard-heading">90% Success Rate</h5>
          <p style={pStyle} className="card-description">
            Meet your business goals and intent with true governance, seamless
            deployment, actionable analytics, and in-depth KPI reports.
          </p>
        </Col>
        <Col style={colStyle}>
          <img src={icon4} style={imgStyle} />
          <h5 className="smallcard-heading">494 Hours Saved Per Year</h5>
          <p style={pStyle} className="card-description">
            Benefit from on-time, on-budget delivery and prevent scope creep
            with accurate cost estimates. Get the job completed on time, always.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default StickyTasksYourWay;
