import React from "react";
import UseCase from "../../UseCases/UseCase";

import Integration from "../../UseCases/IntegrationsAnimUC";

import CTACard from "../../UseCases/PricingSnippetUC";
import UseCases from "../../UseCases/UseCasesIcons";

import FeatureBanner from "../../../assets/imgs/FeatureBanner.png";


import mag from "../../../assets/imgs/marketing&growth.png";



import projeffic from '../../../assets/cases/softwareeng/projecteffic.svg';
import outcome from '../../../assets/cases/softwareeng/outcome.svg';
import review from '../../../assets/cases/softwareeng/reviewspeed.svg';
import execution from '../../../assets/cases/softwareeng/execution.svg';
function SoftwareE() {
  var head = {
    title: `Make your next software project both agile & cost-effective!
    `,
    desc: `Software glitches popping up unexpectedly well, don’t get intimidated. StickyTasks can save you from falling foul of common SDLC problems like this. From timed development stages to hidden stakeholder lethargy, get a handle on pain points and improve project management practices. 
    `,
    img: FeatureBanner,
    subheading: `These four use cases clearly illustrate how StickyTasks makes an engineer’s life a bliss!`,
  };

  var sections = [
    {
      img: projeffic,
      title: `1. The devil is in details: Baseline projects efficiently
      `,
      desc: `Scrum? Check. Kanban? Check. Sprint planning? Check. StickyTasks rich planning features enable the development team to flexibly plan and baseline every project details.
      `,
    },
    {
      img: outcome,
      title: `2. Keep client expectations and outcomes aligned
      `,
      desc: `To ensure that the product outcomes align with expectations and requirements, StickyTasks provides a solid process and line of communication for program managers. 
      `,
    },

    {
      img: review,
      title: `3. Bring review process up to speed`,
      desc: `Websites, prototypes, flashy 360° experiences – they’re all welcome in StickyTasks. Set up review steps for your project in seconds and review it all in a unified place.
      `,
    },
    {
      img: execution,
      title: `4. Transparent execution
      `,
      desc: `Whether your team is across the table or around the world, StickyTasks brings a new level of transparency to your development team's work and keeps everyone on the same page.
      `,
    },
  ];

  var integrationHeading = {
    heading: `Integrate With the Tools You Already Use
    `,
    desc: "",
  };
  const ctaCard = {
    heading: `Supercharge Your Software Projects with StickyTasks
    `,
    desc: ``,
    btnTxt: `Get started with a free account today!`,
  };

  const iconsDataSection = {
    heading: `Integrate With the Tools You Already Use
    `,
    subheading: ``,
    icons: [
      {
        heading: 'Share Unlimited Files',
        link: '',
        img: mag
      },
      {
        heading: 'Get feedback in context',
        link: '',
        img: mag
      },
      {
        heading: 'View your version history',
        link: '',
        img: mag
      },
      {
        heading: ' Log in from anywhere',
        link: '',
        img: mag
      },
      {
        heading: ' Invite unlimited reviewers',
        link: '',
        img: mag
      },
      {
        heading: 'Approve files with one click',
        link: '',
        img: mag
      }
    ]
  };

  return (
    <div>
      <UseCase headerData={head} sectionData={sections} />
      <CTACard
        heading={ctaCard.heading}
        desc={ctaCard.desc}
        ctaText={ctaCard.btnTxt}
      />
      {/* <UseCases heading={iconsDataSection.heading} subheading={iconsDataSection.subheading} iconsData={iconsDataSection.icons}/> */}
      <Integration
        headingText={integrationHeading.heading}
        desc={integrationHeading.desc}
      />
    </div>
  );
}

export default SoftwareE;
