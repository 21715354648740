import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";

const Pricecard = () => {
  const ctnStyle = {
    marginTop: "2rem",
    marginBottom: "2rem",
  };

  const cardCtn = {
    // width: "25%",
    boxShadow: "0px 9px 20px #00000029",
    borderRadius: "10px",
    paddingBottom: "2rem",
    height: "870px",
  };

  const CardPrice = {
    width: "80%",

    margin: "auto",
    background: "white",
    padding: "1rem",
  };

  const CardFeatures = {
    textAlign: "left",
  };

  const cardCtnInside = {
    background: "white",
  };

  const ulStyle = {
    listStyle: "none",
  };

  const liStyle = {
    marginTop: "1rem",
  };

  const GetStratedStyle = {
    width: "80%",
    border: "none",
    background: "#2E2E2E",
    color: "white",
    padding: "0.7rem 1.8rem 0.7rem 1.8rem",
    borderRadius: "8px",
    marginTop: "2rem",
  };

  const swiperStyle = {
    width: "80%",
    // height: "100vh"
    overflow: "visible",
  };

  const slideStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <div>
      <Swiper
        slidesPerView={4}
        spaceBetween={20}
        pagination={{ clickable: true }}
        style={swiperStyle}
        className="mt-4"
      >
        <Container fluid style={ctnStyle}>
          <Row>
            <SwiperSlide style={slideStyle}>
              <Col>
                <div style={cardCtn}>
                  <div
                    style={{
                      background: "#DEDEDE",
                      padding: "1.5rem",
                      borderRadius: "10px",
                    }}
                  >
                    <h4>FREE</h4>
                    <p>
                      Make project management a breeze for individual use with a
                      Free version.
                    </p>
                  </div>
                  <div style={cardCtnInside}>
                    <div style={CardPrice}>
                      <h3 style={{ fontWeight: "700" }}>
                        Free<br></br>
                        <span
                          style={{ fontSize: "16px", fontWeight: "normal" }}
                        >
                          {" "}
                          /FOREVER
                        </span>
                      </h3>
                    </div>
                    <div className="CardFeatures" style={CardFeatures}>
                      <ul style={ulStyle}>
                        <li style={liStyle}>Unlimited Tasks</li>
                        <li style={liStyle}>100GB storage (25MB/file)</li>
                        <li style={liStyle}>List & Kanban Boards</li>
                        <li style={liStyle}>Unlimited activity log</li>
                        <li style={liStyle}>Assignee and due dates</li>
                        <li style={liStyle}>iOS and Android mobile apps</li>
                        <li style={liStyle}>2-factor authentication</li>
                      </ul>
                    </div>
                    <div className="fixed-bottom mb-3">
                      <a
                        href="https://app.stickytasks.com/register?src=stickyweb&sub=&campaign=akdjhfadfyaidfaidyfad897"
                        target="_blank"
                      >
                        <button style={GetStratedStyle}>Try us out</button>
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
            </SwiperSlide>

            <SwiperSlide style={slideStyle}>
              <Col>
                <div style={cardCtn}>
                  <div
                    style={{
                      background: "var(--yellow)",
                      padding: "1.5rem",
                      borderRadius: "10px",
                    }}
                  >
                    <h4 style={{ color: "white" }}>Standard</h4>
                    <p style={{ color: "white" }}>
                      Make project management a breeze for individual use with a
                      Free version.
                    </p>
                  </div>
                  <div style={cardCtnInside}>
                    <div style={CardPrice}>
                      <h3 style={{ fontWeight: "700" }}>
                        $6.0 <br></br>
                        <span
                          style={{ fontSize: "16px", fontWeight: "normal" }}
                        >
                          per month
                        </span>
                      </h3>
                    </div>
                    <div className="CardFeatures" style={CardFeatures}>
                      <ul style={ulStyle}>
                        <li style={liStyle}>Everything in Free, plus:</li>
                        <li style={liStyle}>Board View</li>
                        <li style={liStyle}>Calendar view</li>
                        <li style={liStyle}>Project Templates</li>
                        <li style={liStyle}>500GB storage (25MB/file)</li>
                        <li style={liStyle}>Activity Monitoring</li>
                        <li style={liStyle}>Team Attendance</li>
                        <li style={liStyle}>Payroll Module</li>
                      </ul>
                    </div>
                    <div className="fixed-bottom mb-3">
                      <a
                        href="https://app.stickytasks.com/register?src=stickyweb&sub=price_1Lri53GKQA4JEOXgteL8UR2X&campaign=akdjhfadfyaidfaidyfad897"
                        target="_blank"
                      >
                        <button style={GetStratedStyle}>Get Strated</button>
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
            </SwiperSlide>

            <SwiperSlide style={slideStyle}>
              <Col>
                <div style={cardCtn}>
                  <div
                    style={{
                      background: "#4D53F1",
                      padding: "1.5rem",
                      borderRadius: "10px",
                    }}
                  >
                    <h4 style={{ color: "white" }}>Premium</h4>
                    <p style={{ color: "white" }}>
                      Make project management a breeze for individual use with a
                      Free version.
                    </p>
                  </div>
                  <div style={cardCtnInside}>
                    <div style={CardPrice}>
                      <h3 style={{ fontWeight: "700" }}>
                        $8.0 <br></br>
                        <span
                          style={{ fontSize: "16px", fontWeight: "normal" }}
                        >
                          per month
                        </span>
                      </h3>
                    </div>
                    <div className="CardFeatures" style={CardFeatures}>
                      <ul style={ulStyle}>
                        <li style={liStyle}>Everything in Standard, plus:</li>
                        <li style={liStyle}>Dashboard view</li>
                        <li style={liStyle}>Timeline/Gantt view</li>
                        <li style={liStyle}>Customized templates</li>
                        <li style={liStyle}>Observers</li>
                        <li style={liStyle}>1TB storage (25MB/file)</li>
                        <li style={liStyle}>Priority support</li>
                        <li style={liStyle}>Simple data export</li>
                        <li style={liStyle}>
                          Integration with GoogleDrive / AWS-S3
                        </li>
                        <li style={liStyle}>Numerous Reports</li>
                      </ul>
                    </div>
                    <div className="fixed-bottom mb-3">
                      <a
                        href="https://app.stickytasks.com/register?src=stickyweb&sub=price_1Ls1xXGKQA4JEOXgRmFSJliE&campaign=akdjhfadfyaidfaidyfad897"
                        target="_blank"
                      >
                        <button style={GetStratedStyle}> Get Strated</button>
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
            </SwiperSlide>

            <SwiperSlide style={slideStyle}>
              <Col>
                <div style={cardCtn}>
                  <div
                    style={{
                      background: "#B5B8FF",
                      padding: "1.5rem",
                      borderRadius: "10px",
                    }}
                  >
                    <h4>Enterprise</h4>
                    <p>
                      Make project management a breeze for individual use with a
                      Free version.
                    </p>
                  </div>
                  <div style={cardCtnInside}>
                    <div style={CardPrice} className="pb-4">
                      <h3 style={{ fontWeight: "700" }}>
                        Bespoke <br></br>
                        <p
                          style={{ fontSize: "16px", fontWeight: "normal" }}
                        ></p>
                      </h3>
                    </div>
                    <div className="CardFeatures" style={CardFeatures}>
                      <ul style={ulStyle}>
                        <li style={liStyle}>Everything in Premium, plus:</li>
                        <li style={liStyle}>
                          Multi Organization with Single Account
                        </li>
                        <li style={liStyle}>Multi-board guests</li>
                        <li style={liStyle}>Power-Up Users</li>
                        <li style={liStyle}>Process Customization</li>
                        <li style={liStyle}>Custom Integrations</li>
                        <li style={liStyle}>Numerous Reports</li>
                      </ul>
                    </div>
                    <div className="fixed-bottom mb-3">
                      <a href="https://stickytasks.com/contact">
                        <button style={GetStratedStyle}>Book a demo</button>
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
            </SwiperSlide>
          </Row>
        </Container>
      </Swiper>
    </div>
  );
};

export default Pricecard;
