import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import FeaturedBlog from "../Blog/BlogCard";
import BlogList from "../Blog/BlogList";

function Blog() {
  const ctnStyle = {
    textAlign: "center",
    paddingTop: "4rem",
    paddingBottom: "4rem",
  };
  return (
    <div>
      <Container fluid style={ctnStyle} className="Blog-ctn">
        <h2
          style={{
            width: "40%",
            margin: "auto",
          }}
          className="page-heading"
        >
          Blog
        </h2>
        <p className="blog-mainpage-description">
          Plan, organize, and collaborate on any project with powerful task
          management features that can be customized according to project needs
          and specifications.
        </p>
      </Container>
      <BlogList />
    </div>
  );
}

export default Blog;
