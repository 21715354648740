import React from "react";
import Image1 from "../../../assets/imgs/helpcenter/OnboardGuide/img1.png";
import Image2 from "../../../assets/imgs/helpcenter/OnboardGuide/img2.png";
import Image3 from "../../../assets/imgs/helpcenter/OnboardGuide/img3.png";
import Image4 from "../../../assets/imgs/helpcenter/OnboardGuide/img4.png";
import Image5 from "../../../assets/imgs/helpcenter/OnboardGuide/img5.png";
import Image6 from "../../../assets/imgs/helpcenter/OnboardGuide/img6.png";
import Image7 from "../../../assets/imgs/helpcenter/OnboardGuide/img7.png";
import Image8 from "../../../assets/imgs/helpcenter/OnboardGuide/img8.png";
import Image9 from "../../../assets/imgs/helpcenter/OnboardGuide/img9.png";
import Image10 from "../../../assets/imgs/helpcenter/OnboardGuide/img10.png";
import view from "../../../assets/imgs/helpcenter/OnboardGuide/calview.png";
import last from "../../../assets/imgs/helpcenter/OnboardGuide/last.png";


import { Container, Row, Col } from "react-bootstrap";



function Onboardguide() {


    const width={
        width:"100%",
    }

    const padd={
        paddingTop:"8rem"
    }
    return (


        <Container style={padd}>


            <h2>Onboarding Guide: Starting with The Basics
            </h2>

            <p>We think StickyTasks is one of the best work management platforms around.
            </p>

            <p>Are we biased? Maybe.
            </p>

            <p>But that doesn’t mean we’re wrong. StickyTasks structure can provide a bit of a learning curve, but once you become familiar with it, you hold the power of organizing and managing work on a next level.
            </p>



            <p>Here’s what you need to know for getting on the right foot and onboarding!
            </p>
            <h3>1. StickyTasks Hierarchy</h3>

            <p>The entire StickyTasks app is organized around a hierarchical structure broken down as follows:
            </p>
            <p><b>Home > My Tasks> Real Time > My Projects > Screenshots > Attendance > FAQ > App Activity > Define > Reports 
</b></p>

            <p>There are certain aspects here that might trouble a new user with onboarding however, this detailed review might help you with the entire process of leading it out and in that exact time I may come up with another idea to make the most of it.
            </p>


            <img src={Image1} style={width}/>

            <h3>My Tasks
            </h3>

            <p>My Tasks can be thought of as a large, organizational folder that is used to categorize the content. User has the flexibility to organize data according to different teams, projects, or whatever other categories your team operates with.
            </p>
            <p>Here is a simple breakdown of categories that appear in this section:
            </p>

            <p><b>To Dos > On Hold > In Progress (optional) > Today > Upcoming> Over Due
</b></p>

            <img src={Image2} alt="" srcset="" style={width}/>

            <p>Note: Each StickyTasks Workspace is entirely separate, so it is not possible to view or transfer information between them.
            </p>

            <h3>Real Time</h3>

            <p>Stop wondering what activity is happening in a List, Folder, Space, or your entire Workspace?
            </p>

            <p>Get an aggregated view of all activity across your team in a unified dashboard. Filter for people and type to get granular with the activity you see.
            </p>

            <p>The platform distinguishes your workspace users in line with their activity levels such as, On Work, Not on Work, Idle, All.
            </p>

            <img src={Image3} alt="" srcset="" style={width} />


            <h3>My Projects</h3>

            <p>This is where all of your tasks live.
            </p>


            <p>The biggest challenge for managers in a remote environment is to get a 360 view of things, but StickyTasks puts an end to this bizarre situation. In Projects, mangers or stakeholders can keep tabs of what their employees are up to in both “View” and “Grid” views respectively.
            </p>

            <p>To get a better idea of what we are talking about, have a look at the below screenshot:
            </p>


            <img src={Image4} alt="" srcset="" style={width}/>


            <h3>Screenshots</h3>


            <p>Make every second count!
            </p>

            <p>Automatic time tracking and screenshots lets you know where the team excels and where it needs help so you can easily improve individual and overall performance.
            </p>


            <p><b>|All you have to do is  to sneak a peek into the screenshot tab in the menu, and get access to user screenshots. </b></p>

            <img src={Image5} alt="" srcset="" style={width}/>


            <h3>Team Attendance
            </h3>

            <p>With the right attendance management software, keeping a track of your workforce can be a piece of cake. Our software tracks and monitors how much time an employee spends on a specific task, site or app.
            </p>

            <p>You can customize your employee searches with Filters as needed for each employee enhancing focus and productivity. </p>

            <img src={Image6} alt="" srcset="" style={width}/>


            <p><b>Pro Tip for Optimizing the Hierarchy
            </b></p>


            <p>Use Favorites to create your own sidebar, fully customized with location (Space/Folder/List), sorting, filtering, and your favorite view!
            </p>


            <h2>2. Understanding the Views
            </h2>
            <p><b>List View
            </b></p>

            <p>The most powerful place to manage your tasks.
            </p>


            <p>Sort and filter tasks by just about any attribute, batch edit tasks with the multitask toolbar, and view all tasks across ALL Spaces.</p>
            <img src={Image8} alt="" srcset="" style={width}/>


            <p><b>Board View
            </b></p>

            <p>Have you ever put post-it-notes on a board, used Trello, or taken an agile approach to manage a large goal? In StickyTasks, you can view your tasks as cards on a board where each column is a stage in your custom status workflow.
            </p>

            <p>This view is great for managing bottlenecks.
            </p>
            <img src={Image8} alt="" srcset="" style={width}/>


            <p><b>Kanban View
            </b></p>

            <p>It is great for helping teams collaborate by displaying all project-related Workflows and sprints. Intuitive color-coding makes it easy to see at what stage of the project each task is at.
            </p>
            <img src={view} alt="" srcset="" style={width}/>

            <p><b>Calendar View
            </b></p>

            <p>With the Sidebar, you can zoom in on tasks with a certain List or Folder, or even view all tasks at once!
            </p>

            <img src={last} alt="" srcset="" style={width}/>


            <h2>3. Customize Where You Work
</h2>

<p>Customization is set primarily at the Space level. By customizing Privacy, Integrations, and user settings, you can incorporate unique workflows in each of your separate Spaces. </p>

<p><b>Features with Customizable Access
</b></p>

<p>These incorporate all of the major features in StickyTasks and can be enabled or disabled based on what you need in each Space.
</p>




<img src={Image10} alt="" srcset="" style={width}/>

<h2>Top Aspects and Attributes 
</h2>

<h3>Dependencies
</h3>
<p>Link tasks together based on their requirements to ensure they are completed in the right order. This helps your Workspace flow tasks in an organized way.
</p>

<h3>Notifications
</h3>

<p>Notifications can be a major distraction. StickyTasks keeps things simple and only gives notifications for important items of relevance. Customize notification thresholds and keep things clear.
</p>

<h3>Importing
</h3>

<p>Automatically import your data perfectly from every major project management platform. While we have imports with <b>Asana</b>, <b>Trello</b>,<b> Monday.com</b>, <b>Wrike</b>, <b> Basecamp</b>, and <b> Jira</b>, our CSV import feature is also available if you're transitioning from another platform!
</p>

<p>If you have any questions at all, just reach out to us at <a href="mailto:help@StickyTasks.com"></a>help@StickyTasks.com!
</p>

<h3>Dashboard 
</h3>
<p>Create your own mission control center for your entire team for things like sprint tracking, epics, high-level initiatives, team planning, and literally anything your creative mind can do. 
</p>

<h3>Sort and Filter
</h3>

<p>Tasks aren't very useful until they're organized. Let Stickytasks handle the tediousness and sort your tasks with one click! When the volume gets out of hand, add a filter!
</p>

<h3>Apps
</h3>

<ul>

    <li>Web - Works on any browser
</li>
    <li>Mobile - iOS and Android
</li>
    <li>Desktop - Mac, Windows, and Linux
</li>
    <li>Voice - Alexa, Google Assistant
</li>
    <li>Browser - Chrome Extension
</li>
</ul>

<h3>Integrations
</h3>

<ul>

    <li>Google Drive
</li>
    <li>Google Calendar
</li>
    <li>Slack
</li>
    <li>Github
</li>
    <li>Zapier
</li>
    <li>Outlook
</li>
    <li>Zendesk
</li>
    <li>Gmail
</li>
</ul>

<p>For a rapid overview of our most important features, check out our on-demand and comprehensive demos! 
</p>


        </Container>
    )



}


export default Onboardguide;