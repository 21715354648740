import React from "react";
import UseCase from "../../UseCases/UseCase";

import Integration from "../../UseCases/IntegrationsAnimUC";

import CTACard from "../../UseCases/PricingSnippetUC";
import UseCases from "../../UseCases/UseCasesIcons";

import FeatureBanner from "../../../assets/imgs/FeatureBanner.png";



import mag from "../../../assets/imgs/marketing&growth.png";


import workflow from '../../../assets/cases/marketer/marketer1.svg';
import stayontrack from '../../../assets/cases/marketer/marketer2.svg';
import avoid from '../../../assets/cases/marketer/marketer3.svg';
import launchproject from '../../../assets/cases/marketer/marketer4.svg';

function MarketingP() {
  var head = {
    title: `Marketing is a lot, but don't panic. We've got you covered!`,
    desc: `No need to deal with digital dilemmas anymore. As the most important tool you can have in your digital marketing arsenal, Sticky Tasks is chock-full of project management features that achieve real results.`,
    img: FeatureBanner,
    subheading: `Here we’ll illustrate this fact using four use-cases:`,
  };

  var sections = [
    {
      img: workflow,
      title: `1. Unfunck your workflow`,
      desc: `Stop burying your head in spreadsheets; better ditch them. Instead, automate your project workflows with our customizable shared workspaces, Gantt charts, and calendars.`,
    },
    {
      img: stayontrack,
      title: `2. Stay on track – even when the track changes`,
      desc: `Bring every stakeholder in on the loop and say goodbye to ineffective communication between creators, promoters, and decision-makers. `,
    },

    {
      img: avoid,
      title: `3. Avoid the all-too-familiar black hole of failed projects`,
      desc: `Stay on top of every marketing project in your pipeline with Sticky Task’s task management functionality, resource management capability, and project management tools.`,
    },
    {
      img: launchproject,
      title: `4. Launch projects like a pro`,
      desc: `Reveal your marketing campaign to the world with unmatched precision. StickyTasks makes it easy to keep messaging consistent, maintain brand image, and analyze results—all in real-time.`,
    },
  ];

  var integrationHeading = {
    heading: `Third-party App Integrations for Marketers
    `,
    desc: "",
  };
  const ctaCard = {
    heading: `So, tell us which other marketing project management software you think can pull it off? 
    `,
    desc: `StickyTasks is made up of nothing but marketing project management. So, ask our valued clients (which btw gave StickyTasks Marketing Platform a solid testimonial).`,
    btnTxt: `Get started with a free account today!`,
  };

  const iconsDataSection = {
    heading: `Third-party App Integrations for Marketers
    `,
    subheading: ``,
    icons: [
      {
        heading: 'Share Unlimited Files',
        link: '',
        img: mag
      },
      {
        heading: 'Get feedback in context',
        link: '',
        img: mag
      },
      {
        heading: 'View your version history',
        link: '',
        img: mag
      },
      {
        heading: ' Log in from anywhere',
        link: '',
        img: mag
      },
      {
        heading: ' Invite unlimited reviewers',
        link: '',
        img: mag
      },
      {
        heading: 'Approve files with one click',
        link: '',
        img: mag
      }
    ]
  };

  return (
    <div>
      <UseCase headerData={head} sectionData={sections} />
      <CTACard
        heading={ctaCard.heading}
        desc={ctaCard.desc}
        ctaText={ctaCard.btnTxt}
      />
      {/* <UseCases heading={iconsDataSection.heading} subheading={iconsDataSection.subheading} iconsData={iconsDataSection.icons}/> */}
      <Integration
        headingText={integrationHeading.heading}
        desc={integrationHeading.desc}
      />
    </div>
  );
}

export default MarketingP;
