import React from "react";
import PricingSnippet from "../../UseCases/PricingSnippetUC";
import { Container, Row, Col } from "react-bootstrap";

import Blogimg1 from "../../../assets/blogimgs/Blog6/img1.png";
import Blogimg2 from "../../../assets/blogimgs/Blog6/img2.png";

import Blogimg3 from "../../../assets/blogimgs/Blog6/img3.png";
import Blogimg4 from "../../../assets/blogimgs/Blog6/img4.png";

import Mainimg from "../../../assets/blogimgs/Blog6/main.png";
import './BlogStyling.css';


function Blog6() {
  const priceCard = {
    heading: "Time to Try StickyTasks",
    desc: "",
    ctaText: "Sign up for a free trial today!",
  };

  return (
    <div>
      <Container fluid className="casestudy-hero features-ctn-blog">
        <Row>
          <Col>
            <img src={Mainimg} className="imgStyleBlog6" />
            <h2 className="headingStyle">
              Navigating the New Normal with Remote Work
            </h2>
          </Col>
        </Row>
      </Container>
      <Container fluid className="feature-container-blog">
        <Row>
          <Col md={2}></Col>
          <Col md={8}>
            <p className="section-description">
              In just a few years, we witnessed a diametrical change in the
              proceedings of work culture. It should go without saying that
              Covid has shaken us to the core and made us shift our attention,
              resources to find durable structures in the tech sphere.
            </p>
            <p className="section-description">
              If we look through a worker lens, of course you miss grabbing your
              morning coffee from your favorite cafe, commuting to your office
              and weighing in on random conversations with coworkers. The normal
              21st office rituals which shaped our professional life.{" "}
            </p>

            <ul className="blog6-ul">
            <li>
              <b>Managing Projects:</b> If you have to deal with different
              projects at the same time, it can get overwhelming to not have the
              morale support of colleagues or office focus.
            </li>

            <li>
              <b>Collaboration: </b>Collaborating with team members during
              remote work is tough as you can lack in understanding the
              minuscule details effectively.
            </li>

            <li>
              <b>Tracking tasks and productivity:</b> Leading a team online is
              no joke because leading requires empathy without compromising
              productivity. Remote work can make this task a bit daunting.
            </li>

            <li>
              <b>Building/Maintaining trust:</b> Remote work impacts the
              efficiency of the work and collegiality. It can isolate employees
              and impair their trust with each other or with the administration.
            </li>
            </ul>


            <h1 clasName="entry-title">Business per unusual:</h1>
            <p className="section-description">
              The global impact of this switch (on-site to remote) came abruptly
              with no game plan whatsoever. But with the perfect amount of
              resilience and innovation, the tech community bounced back on its
              feet but more like virtually.
            </p>
            <p className="section-description">
              Let’s go through some of the options;
            </p>
            <h1 clasName="entry-title">1. Slack</h1>

            <img src={Blogimg4}/>

            <p className="section-description">
              Slack provides an easy-to-use communication channel that can keep
              everyone connected with a user-friendly interface.
            </p>
            <p className="section-description">
              Slack is a chat/communication tool designed with a simple
              interface to keep team members connected. It is nearly impossible
              to imagine a remote team that doesn't use this software. Their
              tagline “Where work happens” truly speaks for itself.
            </p>
            <h1 clasName="entry-title">2. Asana</h1>


            <img src={Blogimg3}/>

            <p className="section-description">
              Asana is not new in the game, it has been around since 2008 and
              has become extremely relevant because of its amazing features.
              From project timelines and comprehensive boards to integrations
              and automation, Asana is a one-stop toolkit with thorough
              solutions for any organization.
            </p>
            <p className="section-description">
              Its interface is task-oriented which makes it an excellent tool
              for remote work management.
            </p>
            <h1 clasName="entry-title">3. Jira</h1>
            <img src={Blogimg1}/>
            <p className="section-description">
              Curated by the giant Atlassian, Jira is specifically geared
              towards software development. Jira supports test case management
              and agile software development making it a great tool for
              high-performance teams.
            </p>
            <p className="section-description">
              To create an ecosystem of Atlassian software, Jira integrates with
              other products such as BitBucket and Confluence to make the
              development smooth. =
            </p>

            <h1 clasName="entry-title"> 4. Zoom</h1>
            <img src={Blogimg2}/>


            <p className="section-descriptio">
              Zoom to remote working is like fuel to a car. Essential. Zoom is a
              video conferencing tool that can effortlessly integrate meetings
              directly to Google Calendar.
            </p>
            <p className="section-description">
              A team that does not have enough collaboration and flexible
              workspaces will be unable to comfortably perform Agile processes
              like daily standups or updating the Agile board.
            </p>

            <p className="section-description">
              Although it does have an option for messaging, companies mostly
              prefer it for high-quality video calling.
            </p>

            <p className="section-description">
              One of its vital features is video recordings. That can be used by
              team members to document meeting points.
            </p>

            <h1 clasName="entry-title">Final Thoughts: </h1>
            <p className="section-description">
              Although there is a haze on the horizon and many companies are
              coming back to a bit normal, still these software are nowhere near
              to be obsolete. If anything, they will get more sophisticated and
              interwinted in the new normal of work culture.
            </p>
            <p className="section-description">
              However, providing flexible work arrangements can be a paradigm
              shift for organizations, especially smaller ones that may not have
              the technology, budget, management, and competitive flexibility
              necessary to make extensive use of flexible work arrangements.
            </p>
          </Col>
          <Col md={2}></Col>
        </Row>
      </Container>
      <PricingSnippet
        heading={priceCard.heading}
        desc={priceCard.desc}
        ctaText={priceCard.ctaText}
      />
    </div>
  );
}

export default Blog6;
