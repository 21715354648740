import React from "react";
import {Helmet} from "react-helmet";
import upi from "../../assets/imgs/upi.svg";
import LogoList from "../Pricing/LogoList";
import './Contact.css';
import {
  Col,
  Container,
  Row,
  Form,
  FormCheck,
  FormGroup,
  FormLabel,
  FormControl,
  Button,
} from "react-bootstrap";

const Contact = () => {
  const formButtonStyle = {
    background: "#2E2E2E",
    borderRadius: "5px",
    color: "white",
    border: "none",
    padding: "0.7rem 2.5rem 0.7rem 2.5rem",
    marginTop: "2rem",
  };
  const formTextColor = {
    color: "var(--yellow)",
  };

  const inputStyle = {
    border: "none",

    borderBottom: "1px solid #707070",
    borderRadius: "0px",
  };

  const upiStyle = {
    marginTop: "2rem",
    transform: "scale(0.7)",
  };

  

  return (
    <Container fluid style={{ padding: "0rem" }} className="contact-ctn">
      <Helmet>
            <title>Contact StickyTasks: Get in Touch with Our Support Team</title>
            <meta name="description" content="Get in touch with our team for support or to discuss custom solutions for your business." />
      </Helmet>
      <Row className="col-11" style={{ margin: "auto" }}>
        <Col className="Contcol1Style">
          <h2 className="contact-page-heading">
            Flexible features and pricing just for you.
          </h2>

          <p className="contact-page-description">
            Plan, organize, and collaborate on any project with powerful task
            management that can be customized for every need.
          </p>

          <img src={upi} style={upiStyle} />
        </Col>
        <Col className="Contcol2Style">
          <h2 className="section-heading">Contact Us</h2>
          <Form style={{ marginTop: "0rem" }}>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label style={formTextColor}>Full Name*</Form.Label>
              <Form.Control
                type="text"
                placeholder="John doe"
                style={inputStyle}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label style={formTextColor}>Email*</Form.Label>
              <Form.Control
                type="email"
                placeholder="John-doe@gmail.com"
                style={inputStyle}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="Number">
              <Form.Label style={formTextColor}>Phone Number*</Form.Label>
              <Form.Control
                type="text"
                placeholder="03XX-XXXXXXX"
                style={inputStyle}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="org-name">
              <Form.Label style={formTextColor}>
                Name of Organization*
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="SOFTCEPTION"
                style={inputStyle}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="noe">
              <Form.Label style={formTextColor}>No of Employees*</Form.Label>
              <Form.Control
                type="number"
                placeholder="500 Plus"
                style={inputStyle}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="noe">
              <Form.Label style={formTextColor}>Sales Enquiry*</Form.Label>
              <Form.Control type="text" placeholder="xxxx" style={inputStyle} />
            </Form.Group>

            <Button variant="primary" type="submit" className="cta-btn m-0">
              Submit
            </Button>
          </Form>
        </Col>
      </Row>

      <Container className="text-center mt-5">
        <h2 className="section-heading mb-4">
          Over 15000 customers worldwide rely on Stickytask
        </h2>
        <LogoList />
      </Container>
    </Container>
  );
};

export default Contact;
