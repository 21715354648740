import React from "react";
import PricingSnippet from "../../UseCases/PricingSnippetUC";
import { Container, Row, Col } from "react-bootstrap";

import Blogimg1 from "../../../assets/blogimgs/Blog4/img1.gif";
import Blogimg2 from "../../../assets/blogimgs/Blog4/img2.gif";
import Mainimg from "../../../assets/blogimgs/5min-crash.png";
import "./BlogStyling.css";

function Blog2() {
  const priceCard = {
    heading: "Time to Try StickyTasks",
    desc: "",
    ctaText: "Sign up for a free trial today!",
  };

  return (
    <div>
      <Container fluid className="casestudy-hero features-ctn-blog">
        <Row>
          <Col>
            <img src={Mainimg} className="imgStyle" />
            <h2 className="headingStyle">
              5 Mins Crash Course on How to Baseline a Software Project for
              Optimal Delivery
            </h2>
          </Col>
        </Row>
      </Container>
      <Container fluid className="feature-container-blog">
        <Row>
          <Col md={2}></Col>
          <Col md={8}>
            <p className="section-description">
              While we’ve already lauded the benefits of using OKRs and KPIs to
              improve your trajectory as a scale-up entrepreneur, that doesn’t
              mean there is nothing else to do. Corporate projects in a plethora
              of industries play prominently, and not only that; you can pick up
              some don’ts when it comes to baselining projects.
            </p>
            <p className="section-description">
              Two years back, due to the backdrop of global pandemic, 88% of
              remote employees faced inconsistent management and
              miscommunications across their teams. This issue caused
              unbelievable chaos, and the projects finished on rocks. 1 out of 6
              IT projects had a striking cost overrun of 200%, which means they
              did not have a realistic baseline.
            </p>
            <p className="section-description">
              Moreover, miscommunication and frequent changes in project
              outcomes risk oversteering processes in different directions.
              These bottlenecks can affect your project’s performance, making it
              challenging to fulfill critical success factors.
            </p>

            <p className="section-description">
              So, if you are struggling with creating a project baseline, join
              us on this crash course and discover success-proven tactics that
              can help you along the way.
            </p>

            <h1 class="entry-title">Project Baseline Overview</h1>

            <p className="section-description">
              Preparing a project baseline is the first phase of the project
              management lifecycle. A project baseline plan assists managers and
              project administrators with defining certain perimeters that
              different teams can adhere to. In case of any changes, project
              supervisors can discuss the scope and feasibility before
              implementation.
            </p>

            <p className="section-description">
              “A project baseline is a set of objectives for project rotas,
              cost, and scope.”
            </p>

            <p className="section-description">
              As the project progresses, you then use the project baseline as a
              point of reference to track overall performance. Project baselines
              are categorized into three types listed below:
            </p>

            <h1 class="entry-title">Scope Baseline</h1>

            <p className="section-description">
              This mainly portrays project scope. In addition, it has a rundown
              of objectives and project deliverables to help managers and
              employees focus.
            </p>
            <h1 class="entry-title">Schedule Baseline</h1>
            <p className="section-description">
              This predicts the timeline of the project. Moreover, a schedule
              baseline defines due dates for every deliverable, including a
              final deadline for your project.
            </p>

            <h1 class="entry-title">Cost Baseline</h1>

            <p className="section-description">
              This gives a clear representation of the estimated project budget.
              Your cost baseline breaks down the resources required for each
              deliverable and sums them up to illustrate your estimated total
              project cost.
            </p>

            <h1 class="entry-title">Importance of Capturing Project Baseline</h1>
            <p className="section-description">
              According to our in-house research, project managers perform 71%
              of task management and allocation after the project’s start date.
              This runs the risk of scope creep that jeopardizes projects and
              revenue for professional services organizations.
            </p>
            <p className="section-description">
              Additionally, a solid initial plan helps managers highlight any
              potential disconnects and inconsistencies across project teams
              early on.
            </p>
            <p className="section-description">
              You can take it as an essential part of project initiation to keep
              everyone on the same page regarding cost, scope, schedule, and
              tasks for the project. Finally, a project management baseline
              aligns teams around what they have to work through.
            </p>

            <img src={Blogimg1} className="BlogImgStyle" />

            <p className="section-descriptio">
              Without a defined project baseline plan, managers cannot determine
              if the project is profitable or not. In addition, it helps
              managers forecast project margins, keep tabs on earned value, and
              report numbers to the C suite.
            </p>

            <h1 class="entry-title">
              Common Challenges of Baselining a Project
            </h1>
            <p className="section-description">
              Despite the significance of baselining a project, the process has
              attracted skepticism, and there are many challenges revolving
              around planning. However, by conducting research on how managers
              worldwide tackle these, you will discover a pattern.
            </p>
            <p className="section-description">
              To begin with, they take a snapshot of their initial strategy
              across every baseline category (scope, schedule, and cost). Next,
              they keep this snapshot as a record in stacks of files and call
              them up when they want to monitor project progress. Later, they
              will spend days interpreting the variance on paper into actual
              numbers.
              <h1 class="entry-title">How To Baseline a Project?</h1>
            </p>
            <p className="section-description">
              If you want to create a project baseline that works for you, not
              against you, you must look for a solution that eliminates the
              chaos from project management.
            </p>

            <p className="section-description">
              Sooner or later, you will have to choose a project management
              software like StickyTasks.
            </p>

            <button className="cta-btn">Sign up its-Free</button>
            <p className="section-description">
              As per a recent market analysis, “66% of organizations leveraging
              project management software delivered projects on time within
              their original budget, in contrast to 47% of businesses running
              their project operations without a project management tool.”
            </p>

            <h1 class="entry-title">Challenges</h1>
            <p className="section-description">
              However, this is not true in many situations because not every
              project management software caters to specific requirements such
              as proper progress tracking. Moreover, due to the market
              saturation of such software, it is not easy to choose a platform
              with an end-to-end perspective.
            </p>
            <p className="section-description">
              Since we drink our own champagne, we will show you the best
              StickyTasks can do to end your planning and re-planning
              nightmares.
            </p>

            <h1 class="entry-title">Start with Initial Project Plan</h1>
            <p className="section-description">
              The first step in baselining a project is to develop your initial
              project plan or charter documents, and with StickyTasks you can do
              it in a blink. Then, you can select a project management template
              and get a 360-view of your project plan.
            </p>

        <p className="section-description">
              This pre-built template comprises deliverables, schedule, project
              scope, risks, milestones, and other variables any project manager
              considers.
              </p>

            <p className="section-description">
              Once you develop the project plan, it is time to optimize the
              project baseline by defining its milestones and timeline. Let’s
              move to the next step in this process.
            </p>

            <h1 class="entry-title">Solidify All Milestones And Phases</h1>
            <p className="section-description">
              At this point, you have to go through every project phase and
              ensure that everything is in order, such as:
            </p>

            <li>The flow of the project</li>

            <li>
              Whether you are going into too much detail straight away and
              getting stuck on nuances
            </li>
            <li>
              Focus on capturing more high-level phases like market research,
              design, wireframing, prototyping, etc.
            </li>
            <p className="section-description">
              Then, build logical milestones within these phases. Examples of
              milestones could be when you finish your initial research,
              finalize a product sketch, etc.
            </p>


            <h1 class="entry-title">Add Realistic Buffers To The Project Schedule & Budget
</h1>

            <p className="section-description">
            Sometimes, creating an accurate baseline is often about expecting the unexpected. First, you must ensure that you factor in all possible costs.


            </p>

       
            <p className="section-description">
            The project’s cost may include equipment, consulting fees, materials, insurance, payroll, etc. With all said factors at play, it can be hard to conceptualize the buffers and get an accurate idea of how they affect your project.


            </p>
            <p className="section-description">
            Visualizing the project with milestones, buffers, phases, and other variables makes it easy to know what is going on and take into account the unexpected.


            </p>

            <p className="section-description">
            With StickyTasks, you can easily create a responsive Gantt chart based on any timeline and bring your projects to life!


            </p>
            <h1 class="entry-title">
            Final Verdict!            </h1>

            <img src={Blogimg2} className="BlogImgStyle"/>
            <p className="section-description">
            Regardless of the scale of your project, your project plan is not complete without a baseline. A project’s baseline is a reliable reference point, helping pinpoint things you might have missed so you can quickly adapt to and overcome previously unforeseen bottlenecks and roadblocks.


            </p>

            <p className="section-description">
            Additionally, the baseline of your project can support you in planning any future projects, giving a precise estimate of timeframes and resources required to avoid any inconsistencies and issues.


            </p>
            <p className="section-description">
            With StickyTasks, baselining a project has never been easier. So, give our project planning template a whirl and craft your project baseline in minutes.


            </p>
            <button className="cta-btn">Sign Up-its Free</button>
          </Col>
          <Col md={2}></Col>
        </Row>
      </Container>
      <PricingSnippet
        heading={priceCard.heading}
        desc={priceCard.desc}
        ctaText={priceCard.ctaText}
      />
    </div>
  );
}

export default Blog2;
