import React from "react";
import Contactt from "../Contact/Contact"

function Contact() {
  return (
    <div>
      <Contactt />
    </div>
  );
}

export default Contact;
