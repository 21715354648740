import React from "react";
import { Row, Container, Col } from "react-bootstrap";
import logo from "../../../assets/imgs/wlogo.png";
import googleplay from "../../../assets/imgs/googleplay.png";
import appstore from "../../../assets/imgs/appstore.png";
import { Nav } from "react-bootstrap";

import './Footer.css';


import { Link } from "react-router-dom";

const Footer = () => {
  const footerCtnStyle = {
    backgroundColor: "var(--footer-bg)",
    height: "90%",
    padding: "8rem 0rem 2rem 0rem",
  };

  const footerLogoStyle = {
    width: "150px",
    marginBottom: "1rem",
  };

  const contactStyle = {
    color: "var(--light-white)",
    marginBottom: "0.5rem",
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: "430",
  };



  
  

  const navLinkStyle = {
    color: "white",
    padding: "0px",
    marginBottom: "0.5rem",
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: "430",
    textDecoration: "none"
  };

  const headingStyle = {
    fontSize: "15px",
    color: "var(--white)",
    marginTop: "0.5rem",
    marginBottom: "1rem",
    lineHeight: "23px",
    fontWeight: "544",
  };

  return (
    <div style={footerCtnStyle}>
      <Container>
        <Row>
          <Col  sm={12} md={3}>
            <div>
              <img src={logo} style={footerLogoStyle} />

              <p style={contactStyle}>
                <span style={{ color: "#FFF" }}>Email:</span>{" "}
                info@stickytasks.com
                
              </p>
            </div>

            <div className="stores">
              <img src={googleplay} className="playStoreStyle" />
              <img src={appstore} className="appStoreStyle" />
            </div>
          </Col>
          <Col sm={12} md={3}>
            <h4 style={headingStyle}>Quick Links</h4>

            <Nav
              defaultActiveKey="/home"
              className="flex-column"
              style={navLinkStyle}
            >
              <Link to="/pricing" style={navLinkStyle}>
                Pricing
              </Link>
              <Link to="/contact"  style={navLinkStyle}>
                Contact Us
              </Link>
              <Link to="/contact" style={navLinkStyle}>
                Contact Sales
                </Link>
            </Nav>
          </Col>
          <Col sm={12} md={3}>
            <h4 style={headingStyle}>Features</h4>

            <Nav
              variant="pills"
              defaultActiveKey="/home"
              className="flex-column"
            >
              <Nav.Item>
                <Link to="/features/project-management/" style={navLinkStyle}>Project Management</Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/features/productivity-tracking" style={navLinkStyle}>
                  Productivity Tracking
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/features/application-tracking" style={navLinkStyle}>
                  Application Tracking
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/features/payroll" style={navLinkStyle}>
                  Payroll
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/features/team-management" style={navLinkStyle}>
                  Team management
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/features/employee-monitoring" style={navLinkStyle}>
                  Employee Monitoring
                </Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={12} md={3}>
            <h4 style={headingStyle}>Resources</h4>

            <Nav
              variant="pills"
              className="flex-column"
            >
              <Nav.Item>
                <Link to="/blog" style={navLinkStyle}>Blogs</Link>
              </Nav.Item>

              <Nav.Item>
                <Link to="/case-studies" style={navLinkStyle}>
                  Case Studies
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/help" style={navLinkStyle}>
                  Help Center
                </Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
