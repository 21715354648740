import React from "react";
import Featuress from "../../Features/Features";
import PricingSnippet from "../../Universal/PricingSnippet";

import FeatureBanner from "../../../assets/imgs/Features/TM/TM-Maindashboard.png";
import PlaningImg from "../../../assets/imgs/planning image.png";
import accomodate from "../../../assets/imgs/accomodate.png";



import TrackUserAct from "../../../assets/imgs/Features/TM/TM-TrackUserActivities.png";

import EvaluatesProductivity from '../../../assets/imgs/Features/TM/tman.png';

import MangeWorkLoad from '../../../assets/imgs/Features/TM/TM-ManageWorkload.png';



function TasksM() {
  const priceCard = {
    heading: "Turbocharge your productivity now with our free plan.",
    desc: "No upfront fee, hidden charges, or cost overrun. Do more with less while sticking to your budget. Literally.    ",
  };

  var head = {
    title: `Robust Process Automation
    `,
    desc: `Help your team achieve predetermined goals by managing work tasks with an unmatched level of customization and granularity.
    `,
    img: FeatureBanner,
  };

  var sections = [
    {
      img: MangeWorkLoad,
      title: `Manage Workload
      `,
      desc: `Stop stressing over daily task management. Lead your individual and team tasks with StickyTasks, ensuring complete visibility on project progress and scope.
      `,
    },
    {
      img: TrackUserAct,
      title: `Track User Activities
      `,
      desc: `Throughout your task lifecycle, get timely status updates on business operations and follow every activity using Status check-list on StickyTasks.
      `,
    },
    {
      img: EvaluatesProductivity,
      title: `Team Collaboration
      `,
      desc: `Instead of keeping in silos, organizational data is shared evenly by moving all business communication to private or public channels.
      `,
    },
  ];

  return (
    <div>
      <Featuress headerData={head} sectionData={sections} />
      <PricingSnippet heading={priceCard.heading} desc={priceCard.desc} />
    </div>
  );
}

export default TasksM;
