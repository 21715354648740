import React from "react";

import { Container, Row, Col, Accordion } from "react-bootstrap";
import stayConc from "../../assets/imgs/stayCon.svg";
import "./StayCons.css";

const StayConc = () => {
  const textStyle = {
    marginTop: "1rem",
    marginBottom: "2rem",
    width: "75%",
  };

  return (
    <Container className="stayconc-ctn ctnStyle">
      <Row>
        <Col>
          <h2 className="section-heading">Stay Connected</h2>
          <p style={textStyle} className="section-description">
            Keep your team in a loop. Choose the project view that suits your
            style, and collaborate on the go!
          </p>
          <img src={stayConc} />
        </Col>
        <Col>
          <Accordion defaultActiveKey="0" flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <h4 style={{ color: "var(--black)" }}>What is Sticky Tasks?</h4>
              </Accordion.Header>
              <Accordion.Body>
                Sticky Tasks is productivity software that helps streamline
                workflows and get projects done with ease.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <h4 style={{ color: "var(--black)" }}>
                  Is Sticky Tasks right for me?
                </h4>
              </Accordion.Header>
              <Accordion.Body>
                Absolutely. Sticky Tasks is designed for every user. To better
                understand how Sticky Tasks can deliver results, get in touch
                with our team for a customized solution.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <h4 style={{ color: "var(--black)" }}>Is Sticky Tasks free?</h4>
              </Accordion.Header>
              <Accordion.Body>
                Sticky Tasks is available with a free plan for individuals and
                small teams. To access full features, we recommend you to sign
                up for the premium, business or enterprise plan starting at
                $4/month. Please visit the pricing page for more information.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <h4 style={{ color: "var(--black)" }}>
                  {" "}
                  How do I sign up for Sticky Tasks?
                </h4>
              </Accordion.Header>
              <Accordion.Body>
                It's simple. Go to our{" "}
                <a
                  href="https://app.stickytasks.com/Account/Register"
                  target="_blank"
                >
                  sign up page
                </a>
                , fill in the required information, and you're good to me.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <h4 style={{ color: "var(--black)" }}>How do I get support?</h4>
              </Accordion.Header>
              <Accordion.Body>
                Email us at{" "}
                <a href="mailto:info@stickytasks.com">info@stickytasks.com</a>{" "}
                and we'll get back to you within 1 business day.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
};

export default StayConc;
