import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import BlogCard from "./BlogCard";
import { Link } from "react-router-dom";

import { LinkContainer } from "react-router-bootstrap";

import "./blog.css";

const Blog = () => {
  const btnStyle = {
    border: "none",
    background: "transparent",
    color: "white",
    marginTop: "3rem",
  };

  return (
    <>
      {" "}
      <BlogCard />
      <Container className="blog-ctn ctnStyle">
        <Row className="rowStyle">
          <Col md={4}>
            <Col md={12} className="singleCard blog1col1Style">
              <LinkContainer to="/blog/5minutes-Crash-Course" className="cardParent">
                <div className="custCard" id="custCard0">
                  <div className="card-inner">
                    <div className="info">
                      <div className="upperText">01-12-2022</div>
                      <div className="lowerText">
                        5 Mins Crash Course on How to Baseline a Software
                        Project for Optimal Delivery
                      </div>
                      <div className="rowData"></div>
                      <div className="blog-card-description">
                        While we’ve already lauded the benefits of using OKRs
                        and KPIs to improve your trajectory{" "}
                      </div>
                      <div>
                        <Link className="cta-btn" to="/blog/5minutes-Crash-Course">
                          Read more
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </LinkContainer>
            </Col>
          </Col>
          <Col md={4}>
            <Col md={12} className="singleCard blog2col2Style">
              <LinkContainer to="/blog/Project-Based-Vs-Milestone-Billing" className="cardParent">
                <div className="custCard" id="custCard0">
                  <div className="card-inner">
                    <div className="info">
                      <div className="upperText">01-12-2022</div>
                      <div className="lowerText">
                        Project-Based Vs Milestone Billing: Which Payment
                        Provision Works Best for Project Managers{" "}
                      </div>
                      <div className="rowData"></div>
                      <div className="card-description">
                        Whether you’re a freelancer, project manager, or
                        entrepreneur, implementing the proper billing process is
                        critically
                      </div>
                      <div>
                        <Link className="cta-btn " to="/blog/Project-Based-Vs-Milestone-Billing">
                          Read more
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </LinkContainer>
            </Col>
          </Col>
          <Col md={4}>
            <Col md={12} className="singleCard blog3col3Style">
              <LinkContainer to="/blog/A-Definitive-Guide" className="cardParent">
                <div className="custCard" id="custCard0">
                  <div className="card-inner">
                    <div className="info">
                      <div className="upperText">01-12-2022</div>
                      <div className="lowerText">
                        A Definitive Guide to Make Your Sprint Backlog More
                        Visible, Accessible & Actionable{" "}
                      </div>
                      <div className="rowData"></div>
                      <div className="card-description">
                        “Program management is like a troupe of circus
                        performers standing in a circle, each juggling-three
                      </div>
                      <div>
                        <Link className="cta-btn " to="/blog/A-Definitive-Guide">
                          Read more
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </LinkContainer>
            </Col>
          </Col>
        </Row>

        <Row className="rowStyle">
          <Col md={4}>
            <Col md={12} className="singleCard blog4col1Style">
              <LinkContainer to="/blog/Managing-Crisis" className="cardParent">
                <div className="custCard" id="custCard0">
                  <div className="card-inner">
                    <div className="info">
                      <div className="upperText">01-12-2022</div>
                      <div className="lowerText">
                        Managing Crisis: The Rising Demand of Agile Work
                        Environment for Employee Success
                      </div>
                      <div className="rowData"></div>
                      <div className="blog-card-description">
                        Presently, many companies are in the pursuit of creating
                        an agile work environment for their workforce
                      </div>
                      <div>
                        <Link className="cta-btn" to="/blog/Managing-Crisis">
                          Read more
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </LinkContainer>
            </Col>
          </Col>
          <Col md={4}>
            <Col md={12} className="singleCard blog5col2Style">
              <LinkContainer to="/blog/Navigating-the-New-Normal" className="cardParent">
                <div className="custCard" id="custCard0">
                  <div className="card-inner">
                    <div className="info">
                      <div className="upperText">01-12-2022</div>
                      <div className="lowerText">
                      Navigating the New Normal with Remote Work

                      </div>
                      <div className="rowData"></div>
                      <div className="card-description">
                      In just a few years, we witnessed a diametrical change in the proceedings of work culture.
                      </div>
                      <div>
                        <Link className="cta-btn " to="/blog/Navigating-the-New-Normal">
                          Read more
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </LinkContainer>
            </Col>
          </Col>
          {/* <Col md={4}>
            <Col md={12} className="singleCard blog3col3Style">
              <LinkContainer to="/blog/blog4" className="cardParent">
                <div className="custCard" id="custCard0">
                  <div className="card-inner">
                    <div className="info">
                      <div className="upperText">01-12-2022</div>
                      <div className="lowerText">
                        A Definitive Guide to Make Your Sprint Backlog More Visible,
                        Accessible & Actionable                    </div>
                      <div className="rowData"></div>
                      <div className="card-description">
                        “Program management is like a troupe of circus performers standing
                        in a circle, each juggling-three
                      </div>
                      <div>
                        <Link className="cta-btn " to="/blog/blog4">Read more</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </LinkContainer>
            </Col>
          </Col> */}
        </Row>
      </Container>
    </>
  );
};

export default Blog;
