import React from "react";
import bgImg from "../../assets/imgs/undraw_online_payments.png";
import { Container, Row, Col } from "react-bootstrap";

import './PricingSnippet.css';
const PricingComp = (props) => {
  const priceDescStyle = {
    fontSize: "16px",
    fontWeight: "400",

  };

  return (
    <Container
      fluid className="pricingCtnOutter"
    >
      <Row>
        <Col>
          <h1 className="banner-heading-small" style={{ width: "90%" }}>
            {props.heading}
          </h1>
          <p style={{ width: "85%" }} className="pricing-section-description">
            {props.desc}
          </p>

          <a className="cta-btn "  href="https://app.stickytasks.com/register"
                target="_blank">Sign Up For Free</a>
        </Col>
        <Col>
          <div className="pricingCtn">
            <div className="pricingCtn1Style">
              <p className="purple-heading m-0">Free usage</p>

              <h5 style={{ lineHeight: "22px" }}>
                $0.00
                <span style={priceDescStyle}>
                  <br />
                  Take StickyTasks for a spin. Discover exciting tools,
                  features, and benefits.
                </span>
              </h5>
            </div>
            <div className="pricingCtn2Style">
              <p className="purple-heading m-0">Pay as you go</p>

              <h5 style={{ lineHeight: "22px" }}>
                $6.00/m
                <span style={priceDescStyle}>
                  <br />
                  Only invest in what you use, and scale up or down anytime.
                  Flexible billing, guaranteed.
                </span>
              </h5>
            </div>
          </div>

          <div style={{ textAlign: "right", marginTop: "2rem" }}>
            <img src={bgImg} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default PricingComp;
