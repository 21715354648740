import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import softception from "../../assets/clients/softceptionc.svg";
import hpp from "../../assets/clients/hppc.svg";
import salsoft from "../../assets/clients/salsoftc.svg";
import { Link } from "react-router-dom";
import './CaseStudies.css';

SwiperCore.use([Pagination, Autoplay]);

const CaseStudies = () => {
  const slides = [
    {
      img: softception,
      text1:
        "Softception is a leading, full-funnel growth agency with clients from all over the world. It focuses on providing growth strategies to brands according to their goals, and implements high-performing strategies at the right time and place. ",
      name: "Brad Pit",
      text2:
        " Opposed to using Content here, content here making it look like readable.",
      value: "$256k",
      text3: "Opposed to using",
      link: '/case-study/softception'
    },

    {
      img: hpp,
      text1:
        "Half Price Packaging, caters to customized design and   packaging needs of businesses of all sizes. They focus on providing top-of-the-line packaging options for various products and the most innovative styled boxes at market competitive pricing for all customers.",
      name: "Brad Pit",
      text2:
        " Opposed to using Content here, content here making it look like readable.",
      value: "$256k",
      text3: "Opposed to using",
      link: '/case-study/hpp'
    },
    {
      img: salsoft,
      text1:
        "Salsoft, one of the leading IT companies in Pakistan got on board with Sticky Tasks approximately six months ago and since then they have been able to achieve their goals faster and in a more organized manner.",
      name: "Brad Pit",
      text2:
        " Opposed to using Content here, content here making it look like readable.",
      value: "$256k",
      text3: "Opposed to using",
      link: '/case-study/salsoft'
    },
  ];

  const ctnStyle = {
    textAlign: "center",
    backgroundColor: "#F6F6F6",
    padding: "2rem",
  };

  const rowStyle = {
    gap: "2rem",
    margin: "auto",
  };

 

  const paraStyle = {
    marginTop: "3rem",
  };

  const hrStyle = {
    width: "6rem",
    color: "#707070",
    height: "2px",
    marginTop: "3rem",
  };

  const nameStyle = {
    color: "var(--yellow)",
  };

  const valueCtn = {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "2rem",
  };

  const valueStyle = {
    fontWeight: "bold",
    fontSize: "22px",
    margin: "0",
  };

  const swiperStyle = {
    width: "100%",
  };

  const slideStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const mainCtnStyle = {
    textAlign: "center",
    backgroundColor: "#F6F6F6",
    paddingTop: "3rem",
    paddingBottom: "3rem",
  };

  const swiperContStyle = {
    width: "90%",
    margin: "auto",
  };

  const logoStyle = {
    width: '120px',
    height: '40px',
    
  }

  return (
    <div style={mainCtnStyle} className="section-container">
      <h2 className="section-heading">
        If it sounds too good to be true…it is.<br></br> Because we’ve delivered
        real results to real people.
      </h2>

      <div style={swiperContStyle}>
        <Swiper
          style={swiperStyle}
          modules={[Autoplay]}
          slidesPerView={3}
          spaceBetween={50}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: true,
            pauseOnMouseEnter: true,
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 10
            },
            480: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            640: {
              slidesPerView: 3,
              spaceBetween: 50,
            }
            // when window width is >= 768px
           
          }}


        >
          <Container fluid style={ctnStyle} className="casestudies-ctn">
            <Row style={rowStyle} className="col-11">
              {slides.map((slide, index) => {
                return (
                  <SwiperSlide style={slideStyle} key={index} >
                    <Col className="cardStyle">
                      <img src={slide.img} style={logoStyle} />
                      <p style={paraStyle} className="card-description">
                        {slide.text1}
                      </p>
                      <hr style={hrStyle}></hr>
                      <Link to={slide.link} className="uc-btn">Case Study</Link>
                      {/* <p style={nameStyle}>{slide.name}</p>
                      <p>{slide.text2}</p>
                      <div style={valueCtn}>
                        <div>
                          <p style={valueStyle}>{slide.value}</p>
                          <p>{slide.text3}</p>
                        </div>
                        <div></div>
                      </div> */}
                    </Col>
                  </SwiperSlide>
                );
              })}
            </Row>
          </Container>
        </Swiper>
      </div>
    </div>
  );
};

export default CaseStudies;
