import React from "react";
import {Helmet} from "react-helmet";
import Featuress from "../../Features/Features";
import PricingSnippet from "../../Universal/PricingSnippet";

import FeatureBanner from "../../../assets/imgs/FeatureBanner.png";
import PlaningImg from "../../../assets/imgs/planning image.png";
import accomodate from "../../../assets/imgs/accomodate.png";


import OneDashboard from '../../../assets/imgs/OneDash.png';
import PayForProductivity from '../../../assets/imgs/PayForProd.png';
import TrackPayRoll from '../../../assets/imgs/trackPayRoll.png';
import PayRollBan from '../../../assets/imgs/PayRollBanner.png';


function Payroll() {
  const priceCard = {
    heading: "Turbocharge your productivity now with our free plan.",
    desc: "No upfront fee, hidden charges, or cost overrun. Do more with less while sticking to your budget. Literally.    ",
  };

  var head = {
    title: `Automated Payroll
    `,
    desc: `Processing timesheets, resolving discrepancies, and re-entering data manually can be a hassle when creating payroll. Enable payroll automation with Sticky Tasks and eliminate silos.
    `,
    img: PayRollBan,
  };

  var sections = [
    {
      img: OneDashboard,
      title: `One Dashboard
      `,
      desc: `To boost overall business efficiency, StickyTasks offers a detailed dashboard for a quick analysis and edits. Move to the next step and try it for free!
      `,
    },
    {
      img: PayForProductivity,
      title: `Capitalize On Productivity
      `,
      desc: `In addition to enabling an efficient payroll process, Sticky Tasks allows your team to use their time productively and increase output.
      `,
    },
    {
      img: TrackPayRoll,
      title: `Track Payroll
      `,
      desc: `Cut the time spent on tracking payments with in-depth reports from Sticky Tasks. Oversee both past and incoming payments to save time and effort, whether you manage remote or in-office teams.
      `,
    },
  ];

  return (
    <div>
      <Helmet>
            <title>StickyTasks Payroll: Streamline Your Payroll Processes</title>
            <meta name="description" content="StickyTasks' payroll feature allows for easy tracking and management of employee pay and benefits." />
      </Helmet>
      <Featuress headerData={head} sectionData={sections} />
      <PricingSnippet heading={priceCard.heading} desc={priceCard.desc} />
    </div>
  );
}

export default Payroll;
