import React from "react";
import Featuress from "../../Features/Features";
import PricingSnippet from "../../Universal/PricingSnippet";

import FeatureBanner from "../../../assets/imgs/FeatureBanner.png";
import PlaningImg from "../../../assets/imgs/planning image.png";
import accomodate from "../../../assets/imgs/accomodate.png";


import accurateestimate from "../../../assets/imgs/ae.png";

import EvaluatesProductivity from '../../../assets/imgs/ep.png';

import PreciseBillableHours from '../../../assets/imgs/pbh.png';

function Reports() {
  const priceCard = {
    heading: "Turbocharge your productivity now with our free plan.",
    desc: "No upfront fee, hidden charges, or cost overrun. Do more with less while sticking to your budget. Literally.    ",
  };

  var head = {
    title: `BI Reports & Preset Dashboards
    `,
    desc: `With the detailed BI reports, StickyTasks helps businesses to streamline their revenue streams and stay competitive in the market.
    `,
    img: FeatureBanner,
  };

  var sections = [
    {
      img: accurateestimate,
      title: `Budget Administration
      `,
      desc: `No matter the size of enterprise, every organization requires a built-in BI software to increase operational efficiency. With an intelligent system for project budgeting operations get an edge over your competitors.
      `,
    },
    {
      img: PreciseBillableHours,
      title: `Save Time
      `,
      desc: `The BI feature of StickyTasks unifies multiple data sources, which help managers and employees spend less time tracking down information and can focus on what matters.
      `,
    },
    {
      img: EvaluatesProductivity,
      title: `Scheduled Reports
      `,
      desc: `In a data-driven world, StickyTasks helps companies monitor KPIs set for their employees with detailed reports, scheduled according to employer preferences.
      `,
    },
  ];

  return (
    <div>
      <Featuress headerData={head} sectionData={sections} />
      <PricingSnippet heading={priceCard.heading} desc={priceCard.desc} />
    </div>
  );
}

export default Reports;
