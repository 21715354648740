import React from "react";
import UseCase from "../../UseCases/UseCase";

import Integration from "../../UseCases/IntegrationsAnimUC";

import CTACard from "../../UseCases/PricingSnippetUC"
import UseCases from "../../UseCases/UseCasesIcons";

import FeatureBanner from "../../../assets/imgs/FeatureBanner.png";

import TrackItAll from "../../../assets/cases/remotework/rm2.svg";
import DefineTarget from "../../../assets/cases/remotework/remotework.svg";

import StreamLine from "../../../assets/cases/remotework/rm3.svg";
import mag from "../../../assets/imgs/marketing&growth.png";



function RemoteWork() {
  var head = {
    title: `Get Remote Ready In Minutes
    `,
    desc: `StickyTasks is the new, essential project management solution for remote teams in 2022`,
    img: FeatureBanner,
    subheading: `Let Your Business Make An Impact With StickyTasks Work Management Suite`
  };

  var sections = [
    {
      img: DefineTarget,
      title: `1. Task management blows up on StickyTasks for one reason, organization`,
      desc: `Instead of wasting hours consolidating tasks from email threads, video calls, and chat, your team can focus on creating their best work.`,
    },
    {
      img: TrackItAll,
      title: `2. Raise the bar with a seamless review process
      `,
      desc: `Make sure all your files go through a structured review process, so nothing misses your eye without being approved by the concerned parties. 
      `,
    },

    {
      img: StreamLine,
      title: `3. Race across the finish line with optimal deliverables 
      `,
      desc: `Let’s free your remote team up to get productive with automated file sharing, reminders, and status updates. With timely feedback, you can spend less time chasing people for approval and more time moving projects forward.
      `,
    },
  ];

  var integrationHeading = {
    heading: `Link Up Your Favorite Third-Part App & Save Hours of Trouble`,
    desc: "",
  };
  const ctaCard = {
    heading: `Unlock 360° Visibility Across Remote Teams with StickyTasks`,
    desc: `Tired of juggling b/w multiple work from home tools? Now you can keep all your apps together in StickyTasks. Eliminate distraction and stay focused with one remote working software platform.`,
    btnTxt: `Get started with a free account today!`
  };

  const iconsDataSection = {
    heading: `There’s A Template for That!`,
    subheading: `There’s no time to start from scratch, chances are we have a template for that.`,
    icons: [
      {
        heading: 'Test',
        link: '',
        img: mag
      },
      {
        heading: 'Test222',
        link: '',
        img: mag
      }
    ]
  };

  return (
    <div>
      <UseCase headerData={head} sectionData={sections} />
      <CTACard heading={ctaCard.heading} desc={ctaCard.desc} ctaText={ctaCard.btnTxt} />
      {/* <UseCases heading={iconsDataSection.heading} subheading={iconsDataSection.subheading} iconsData={iconsDataSection.icons}/> */}
      <Integration
        headingText={integrationHeading.heading}
        desc={integrationHeading.desc}
      />
    </div>
  );
}

export default RemoteWork;
