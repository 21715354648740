import React from "react";
import {Helmet} from "react-helmet";
import { Col, Container, Row } from "react-bootstrap";
import Mainimg from "../../assets/blogimgs/Blog1/blog1.png";
import { Link } from 'react-router-dom';

import { LinkContainer } from "react-router-bootstrap";

const BlogCard = (props) => {

  const ctnStyle = {
    padding: "1rem",
    width: "85%",
    borderRadius: "10px",
    marginTop: "2rem",
    marginBottom: "4rem",
  };

  const headingStyle = {
    width: "90%",
  };

 

  const width={
    width:"99%"
  }

  return (
    <Container style={ctnStyle} >
      <Helmet>
            <title>StickyTasks Blog: Tips and Tricks for Better Task Management</title>
            <meta name="description" content="Stay up to date on the latest tips, tricks, and best practices for using StickyTasks with our blog." />
      </Helmet>
      <Row>



        <Col md={6} sm={12} >
          <img src={Mainimg} style={width}/>
        </Col>
        <Col md={6} sm={12}>
          <h2 style={headingStyle} >
          OKR vs. KPI: A Project Manager’s Guide to Choose the Right
              Goal-Setting Framework
          </h2>
          <p className="blog-section-description">
          “No single factor has more impact on employee engagement than
              clearly defined goals that are
          </p>
          <Link className="cta-btn m-0" to="/blog/Project-Manager-Guide">{props.btnText}Learn More</Link>
        </Col>

      </Row>
    </Container>
  );
};

export default BlogCard;
