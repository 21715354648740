import React from "react";
import {Helmet} from "react-helmet";
import Featuress from "../../Features/Features";
import PricingSnippet from "../../Universal/PricingSnippet";

import FeatureBanner from "../../../assets/imgs/FeatureBanner.png";
import PlaningImg from "../../../assets/imgs/planning image.png";
import accomodate from "../../../assets/imgs/accomodate.png";



import OneDashboard from '../../../assets/imgs/OneDash.png';
import PayForProductivity from '../../../assets/imgs/PayForProd.png';
import TrackPayRoll from '../../../assets/imgs/trackPayRoll.png';

function EmployeeMo() {
  const priceCard = {
    heading: "Turbocharge your productivity now with our free plan.",
    desc: "No upfront fee, hidden charges, or cost overrun. Do more with less while sticking to your budget. Literally.    ",
  };

  var head = {
    title: `Start Monitoring Your Team With Complete Visibility And Transparency`,
    desc: `Bring transparency and accountability into the workplace with employee monitoring capabilities that amplify success
    `,
    img: FeatureBanner,
  };

  var sections = [
    {
      img: OneDashboard,
      title: `Remote Tracking
      `,
      desc: `Sticky Tasks helps boost overall productivity by delivering actionable data and detailed insights on employee performance.
      `,
    },
    {
      img: PayForProductivity,
      title: `Focused Workforce
      `,
      desc: `With detailed performance tracking and other distraction management features, plugins like StickyTasks can assist in keeping teams more focused during working hours.
      `,
    },
    {
      img: TrackPayRoll,
      title: `Easier Performance Evaluation
      `,
      desc: `With consistent performance tracking on a unified platform, you don't need to take anyone's word. Simply make data-backed verdicts on individual employee performance.
      `,
    },
  ];

  return (
    <div>
      <Helmet>
            <title>Best Employee Monitoring APP: Boost Productivity and Accountability - StickyTasks</title>
            <meta name="description" content="Monitor employee productivity and performance with StickyTasks' employee monitoring feature." />
      </Helmet>
      <Featuress headerData={head} sectionData={sections} />
      <PricingSnippet heading={priceCard.heading} desc={priceCard.desc} />
    </div>
  );
}

export default EmployeeMo;
