import React from "react";
import {Helmet} from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";

import StayConc from "./StayConc";
import Pricecard from "./Pricecard";
import Logos from "./LogoList";
import './Pricing.css';
import { Link } from "react-router-dom";



const Pricing = () => {
  const ctnStyle = {

  };



  return (
    <div>
       <Helmet>
            <title>StickyTasks Pricing: Affordable Plans for Every Team</title>
            <meta name="description" content="Discover our pricing plans for StickyTasks and find the perfect fit for your team's needs." />
      </Helmet>
      <Container fluid className="pricing-ctn">
        <h2

          className="page-heading"
        >
          Our Pricing <br />
          Pay For What You Need!
        </h2>
        <p

          className="page-description"
        >
          Plan, organize, and collaborate on any project with powerful task
          management features that can be customized according to project needs
          and specifications.
        </p>
        <Link to="/contact" className="cta-btn m-0 mt-4">Talk to us</Link>

        <Pricecard />
      </Container>

      <StayConc />

      <Container style={ctnStyle}>
        <h2 className="section-heading mb-4">
          Over 15000 customers worldwide rely on Stickytask
        </h2>
        <Logos />
      </Container>
    </div>
  );
};

export default Pricing;

