import React from "react";
import {Helmet} from "react-helmet";
import PricingSnippet from "../../UseCases/PricingSnippetUC";
import { Container, Row, Col } from "react-bootstrap";

function HPP() {
  const priceCard = {
    heading: "Time to Try StickyTasks",
    desc: '',
    ctaText: 'Sign up for a free trial today!'
  };

  return (
    <div>
      <Helmet>
            <title>StickyTasks Case Study: Softception</title>
            <meta name="description" content="Learn how Softception successfully implemented StickyTasks to improve their team's workflow and productivity." />
      </Helmet>
      <Container fluid className="casestudy-hero features-ctn">
        <Row>
          <Col>
            <h2 className="page-heading">Softception</h2>
            <p className="page-description mt-2">
              Softception is a leading, full-funnel growth agency with clients
              from all over the world. It focuses on providing growth strategies
              to brands according to their goals, and implements high-performing
              strategies at the right time and place.
            </p>
          </Col>
        </Row>
      </Container>
      <Container fluid className="feature-container">
        <Row>
          <Col md={2}>
          </Col>
          <Col md={8}>
            <h1 class="entry-title">Problems They Were Looking to Solve</h1>
            <p className="section-description">
              With a partially hybrid team working around the clock, the
              managerial level staff at Softception was facing several
              challenges in managing their teams in a uniform and organized
              manner.
              <br />
              For a business like Softception one of the biggest challenges is
              assigning and completing tasks efficiently in a timely manner
              considering there are times when most members of the team are
              working at different hours.
            </p>

            <h1 class="entry-title mt-5">Why StickyTasks</h1>
            <p className="section-description">
              Though Softception did try a few collaboration tools, StickyTasks
              proved to be their top choice. Whether an employee was working
              during a day shift or night, StickyTasks made it as easy as eating
              cake to create easy to manage workflows. With easy to-do lists and
              employee monitoring features, managers are now able to track their
              team’s efficiency at any time - whether they are on a work trip or
              taking a few hours away due to workload.
              <br />
              Another feature the company has really benefited from is the app
              integration, agencies have to follow the means of communication
              their clients prefer, and with various clients being managed, it
              is not easy to keep track of several apps at the same time.
              StickyTasks allows their team to manage all platforms in one
              place!
              <br /><br/>
              “We have been using StickyTasks for over six months now, and there
              is not a single feature that I think our team has not benefited
              from. It surely is the best team collaboration tool I could find!”
              <br/>
              <b>Ahmad Hayat - Founding Partner</b>
            </p>
          </Col>
          <Col md={2}>
          </Col>
        </Row>
      </Container>
      <PricingSnippet heading={priceCard.heading} desc={priceCard.desc} ctaText={priceCard.ctaText}/>
    </div>
  );
}

export default HPP;
