import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import setting from "../../assets/imgs/Layer 2.png";
import fund from "../../assets/imgs/Layer 1.png";
import control from "../../assets/imgs/rtc.svg";
import { LinkContainer } from "react-router-bootstrap";

const UseCase = () => {
  const colStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    flexDirection:"column",
    gap:"10px",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
    maxWidth:"250px",
    padding:"22px",
  };

  const ctnStyle = {
    textAlign: "center",
    gap: "3rem",
  };

  const rowStyle = {
    width: "70%",
    margin: "45px auto 12px auto",
    gap:"30px",
    justifyContent: "center",

  };

  const linkStyle = {
    marginTop: ".5rem",
    marginLeft: "1rem",
    cursor: "pointer",
  };
  const subHeading = {
    fontSize:"20px",
    fontWeight: "bold"
  }
  const imgStyle = {
    width:"30%",
    objectFit: "contain"
  }
  const controlimgStyle = {
    width:"50%",
    objectFit: "contain"
  }
  return (
    <div>
      <Container fluid style={ctnStyle} className="normal-container usecases-ctn">
        <h2 className="section-heading">Getting Started in Just 3 Steps</h2>
        {/* <p className="section-description">
          We Have One Solution For Multiple Challenges
        </p> */}

        <Row style={rowStyle}>
          <Col style={colStyle}>
            <div>
              <h3 className="step">Step 1</h3>
            </div>
            <div>
              <img style={imgStyle} src={fund} />
            </div>
            {/* <LinkContainer to="/cases/designers" style={linkStyle}>
              
            </LinkContainer> */}
            <div>
              <h3 style={subHeading}>Create An Account</h3>
            </div>
            <div>
                <p className="uc-heading">Register with our Free Trial account</p>
            </div>
          </Col>
          <Col style={colStyle}>
            <div>
              <h3 className="step">Step 2</h3>
            </div>
            <div>
              <img style={imgStyle} src={setting} />
            </div>
            {/* <LinkContainer to="/cases/designers" style={linkStyle}>
              
            </LinkContainer> */}
            <div>
              <h3 style={subHeading}>Basic Setup</h3>
            </div>
            <div>
                <p className="uc-heading">Invite team members, add projects, assign roles</p>
            </div>
          </Col>
          <Col style={colStyle}>
            <div>
              <h3 className="step">Step 3</h3>
            </div>
            <div>
              <img style={controlimgStyle} src={control} />
            </div>
            {/* <LinkContainer to="/cases/designers" style={linkStyle}>
              
            </LinkContainer> */}
            <div>
              <h3 style={subHeading}>Take Control</h3>
            </div>
            <div>
                <p className="uc-heading">Start using with our guided tour</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UseCase;
