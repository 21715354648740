import React from "react";
import Featuress from "../../Features/Features";
import PricingSnippet from "../../Universal/PricingSnippet";

import FeatureBanner from "../../../assets/imgs/FeatureBanner.png";
import PlaningImg from "../../../assets/imgs/planning image.png";
import accomodate from "../../../assets/imgs/accomodate.png";



import OneDashboard from '../../../assets/imgs/OneDash.png';
import PayForProductivity from '../../../assets/imgs/PayForProd.png';
import TrackPayRoll from '../../../assets/imgs/trackPayRoll.png';


function Reports() {
  const priceCard = {
    heading: "Turbocharge your productivity now with our free plan.",
    desc: "No upfront fee, hidden charges, or cost overrun. Do more with less while sticking to your budget. Literally.    ",
  };

  var head = {
    title: `Tap into a Unified Platform
    `,
    desc: `StickyTasks is fully compatible with other applications. You can integrate it with other work management tools such as Slack, Outlook, and Google.
    `,
    img: FeatureBanner,
  };

  var sections = [
    {
      img: OneDashboard,
      title: `Highly Customizable
      `,
      desc: `Every organization is unique to their operations and management thus, StickyTasks offers flexible configurations. There is a wide range of options available for businesses allowing them to customize in line with their needs and preferences. In addition to customization, many options at StickyTasks can be turned on and off as needed.
      `,
    },
    {
      img: PayForProductivity,
      title: `API`,
      desc: `When it comes to integrations, StickyTasks API (application programming interface) enables user software to interact directly with StickyTasks. Enjoy the ultimate freedom with StickyTasks!
      `,
    },
  ];

  return (
    <div>
      <Featuress headerData={head} sectionData={sections} />
      <PricingSnippet heading={priceCard.heading} desc={priceCard.desc} />
    </div>
  );
}

export default Reports;
