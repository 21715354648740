import React from "react";
import { Col, Container, Row } from "react-bootstrap";


import { LinkContainer } from "react-router-bootstrap";

import { Link } from 'react-router-dom';

import './case.css';
const Cases = () => {


  return (
    <Container className="caseslist-ctn ctnStyle">
      <Row className="rowStyle">
        <Col md={4}>
          <Col md={12} className="singleCard col1Style">
            <LinkContainer to="/case-study/salsoft" className="cardParent">
              <div className="custCard" id="custCard0">
                <div className="card-inner">
                  <div className="info">
                    <div className="upperText">01-12-2022</div>
                    <div className="lowerText">
                      Salsoft Technologies</div>
                    <div className="rowData"></div>
                    <div className="blog-card-description">
                      Salsoft, one of the leading IT companies in Pakistan got on board with Sticky Tasks approximately six months ago and since then they have been able to achieve their goals faster and in a more organized manner.<div>
                      </div>

                      <div style={{ marginTop: "20px" }}>
                        <Link className="cta-btn m-0 mt-3" to="/case-study/salsoft">Read more</Link>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </LinkContainer>
          </Col>
        </Col>
        <Col md={4}>
          <Col md={12} className="singleCard col2Style">
            <LinkContainer to="/case-study/softception" className="cardParent">
              <div className="custCard" id="custCard0">
                <div className="card-inner">
                  <div className="info">
                    <div className="upperText">01-12-2022</div>
                    <div className="lowerText">
                      Softception

                    </div>
                    <div className="rowData"></div>
                    <div className="card-description">
                      Softception is a leading, full-funnel growth agency with clients from all over the world. It focuses on providing growth strategies to brands according to their goals, and implements high-performing strategies at the right time and place.
                    </div>
                    <div style={{ marginTop: "20px" }}>
                      <Link className="cta-btn m-0 mt-3" to="/case-study/softception">Read more</Link>
                    </div>
                  </div>
                </div>
              </div>
            </LinkContainer>
          </Col>
        </Col>
        <Col md={4}>
          <Col md={12} className="singleCard col3Style">
            <LinkContainer to="/case-study/hpp" className="cardParent">
              <div className="custCard" id="custCard0">
                <div className="card-inner">
                  <div className="info">
                    <div className="upperText">01-12-2022</div>
                    <div className="lowerText">
                      Half Price Packaging                     </div>
                    <div className="rowData"></div>
                    <div className="card-description">
                      Half Price Packaging, caters to customized design and   packaging needs of businesses of all sizes. They focus on providing top-of-the-line packaging options for various products and the most innovative styled boxes at market competitive pricing for all customers.
                    </div>
                    <div style={{ marginTop: "20px" }}>
                      <Link className=" mt-3 cta-btn" to="/case-study/hpp">Read more</Link>
                    </div>
                  </div>
                </div>
              </div>
            </LinkContainer>
          </Col>
        </Col>

      </Row>


    </Container>

  );
};

export default Cases;
