import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const UseCase = (props) => {

  const GetStratedStyle = {
    border: "none",
    background: "#2E2E2E",
    color: "white",
    padding: "0.7rem 1.8rem 0.7rem 1.8rem",
    borderRadius: "8px",
    marginTop: "2rem",
  };

  const colStyle = {
    display: "flex",
    flexDirection: "column",
    // alignItems:"center",
    justifyContent: "center",
    textAlign: "left",
  };

  return (
    <div>
      <Container fluid className="feature-hero">
        <Row>
          <Col>
            <h2 className="page-heading">{props.headerData.title}</h2>
            <p className="page-description mt-2">{props.headerData.desc}</p>
            <a className="cta-btn"  href="https://app.stickytasks.com/Account/Register"
                target="_blank">Get Started</a>
            <img src={props.headerData.img} className="hero-img" />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col>
            <h2 className="section-heading text-center mt-5">
              {props.headerData.subheading}
            </h2>
          </Col>
        </Row>
      </Container>
      <Container fluid className="feature-container">
        {props.sectionData.map((section, index) => {
          if (index % 2 == 0) {
            //left image
            return (
              <Row className="feature-left mt-5" key={index}>
                <Col className="img-col" sm={12} md={6}>
                  <img src={section.img} className="feature-img" />
                </Col>
                <Col style={colStyle} className="text-col" sm={12} md={6}>
                  <h2 className="section-heading">{section.title}</h2>
                  <p className="section-description">{section.desc}</p>
                </Col>
              </Row>
            );
          } else {
            //right image
            return (
              <Row className="feature-right " key={index}>
                <Col style={colStyle} className="text-col" sm={12} md={6}>
                  <h2 className="section-heading">{section.title}</h2>
                  <p className="section-description">{section.desc}</p>
                </Col>
                <Col
                  className="img-col order-first order-md-last"
                  sm={12}
                  md={6}
                >
                  <img src={section.img} className="feature-img" />
                </Col>
              </Row>
            );
          }
        })}
      </Container>
    </div>
  );
};

export default UseCase;
