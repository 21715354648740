import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import mag from "../../assets/imgs/marketing&growth.png";
import product from "../../assets/imgs/product.png";
import remotework from "../../assets/imgs/remotework.png";
import smallbusiness from "../../assets/imgs/smallbusiness.png";
import softwareeng from "../../assets/imgs/softwareeng.png";
import stickytask from "../../assets/imgs/stickytask.png";
import { LinkContainer } from "react-router-bootstrap";

const UseCaseUC = (props) => {
  const colStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
  };

  const ctnStyle = {
    textAlign: "center",
    gap: "3rem",
  };

  const rowStyle = {
    width: "70%",
    marginTop: "1rem",
    margin: "auto",
  };

  const linkStyle = {
    marginTop: ".5rem",
    marginLeft: "1rem",
    cursor: "pointer",
  };
  return (
    <div>
      <Container fluid style={ctnStyle} className="normal-container">
        <h2 className="section-heading">{props.heading}</h2>
        <p className="section-description">{props.subheading}</p>

        <Row style={rowStyle}>
          {props.iconsData.map((icon, index) => {
            return (
              <Col style={colStyle} md={4} key={index}>
                <div>
                  <img src={icon.img} />
                </div>
                <LinkContainer to={icon.link} style={linkStyle}>
                  <div>
                    <p className="uc-heading">{icon.heading}</p>
                  </div>
                </LinkContainer>
              </Col>
            );
          })}
          
        </Row>
      </Container>
    </div>
  );
};

export default UseCaseUC;
