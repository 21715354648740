import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import mag from "../../assets/imgs/marketing&growth.png";
import product from "../../assets/imgs/product.png";
import remotework from "../../assets/imgs/remotework.png";
import smallbusiness from "../../assets/imgs/smallbusiness.png";
import softwareeng from "../../assets/imgs/softwareeng.png";
import stickytask from "../../assets/imgs/stickytask.png";
import { LinkContainer } from "react-router-bootstrap";

const UseCase = () => {
  const colStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
  };

  const ctnStyle = {
    textAlign: "center",
    gap: "3rem",
  };

  const rowStyle = {
    width: "70%",
    marginTop: "1rem",
    margin: "auto",
  };

  const linkStyle = {
    marginTop: ".5rem",
    marginLeft: "1rem",
    cursor: "pointer",
  };
  return (
    <div>
      <Container fluid style={ctnStyle} className="normal-container usecases-ctn">
        <h2 className="section-heading">Use Cases</h2>
        <p className="section-description">
          We Have One Solution For Multiple Challenges
        </p>

        <Row style={rowStyle}>
          <Col style={colStyle}>
            <div>
              <img src={mag} />
            </div>
            <LinkContainer to="/cases/marketing" style={linkStyle}>
              <div>
                <p className="uc-heading">Marketing & growth</p>
              </div>
            </LinkContainer>
          </Col>
          <Col style={colStyle}>
            <div>
              <img src={remotework} />
            </div>

            <LinkContainer to="/cases/remote-work" style={linkStyle}>
              <div>
                <p className="uc-heading">Remote Work/Teams</p>
              </div>
            </LinkContainer>
          </Col>

          <Col style={colStyle}>
            <div>
              <img src={stickytask} />
            </div>
            <LinkContainer to="/cases/designers" style={linkStyle}>
              <div>
                <p className="uc-heading">StickyTask for Designers</p>
              </div>
            </LinkContainer>
          </Col>
        </Row>

        <Row style={rowStyle}>
          <Col style={colStyle}>
            <div>
              <img src={softwareeng} />
            </div>
            <LinkContainer to="/cases/software-engineers" style={linkStyle}>
              <div>
                <p className="uc-heading">Software Engineers</p>
              </div>
            </LinkContainer>
          </Col>
          <Col style={colStyle}>
            <div>
              <img src={smallbusiness} />
            </div>
            <LinkContainer to="/cases/smb" style={linkStyle}>
              <div style={{ marginTop: ".5rem", marginLeft: "1rem" }}>
                <p className="uc-heading">Small Businesses</p>
              </div>
            </LinkContainer>
          </Col>

          <Col style={colStyle}>
            <div>
              <img src={product} />
            </div>

            <div style={{ marginTop: ".5rem", marginLeft: "1rem" }}>
              <p className="uc-heading">Product Development</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UseCase;
