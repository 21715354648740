import React from 'react';
import { Container, Row, Col } from "react-bootstrap";

import protip from "../../../assets/imgs/helpcenter/NewWorkspace/protip.png";


const HowCanICreate = () => {

    
    const width = {
        width: "60%",
        marginLeft:"10rem"
       
    }

    const padd={
        paddingTop:"8rem"
    }
  return (
  <Container style={padd}>

    <h2>How Can I Create a New Workspace?
</h2>

<p>
At StickyTasks, we are constantly aiming to improve team collaborations and boosting overall business productivity.
</p>

<h3>About Workspaces
</h3>
<p>There is no denying that work becomes messy as teams and workloads grow. The real challenge is having multiple departments and teams using one platform. 
</p>


<p>Keeping these user hustles in mind, we've created Workspaces, which <b>provide your account with a hierarchy of organization to help you better manage multiple departments, teams, and projects</b> in one unified place.
</p>

<p>With multiple tabs running in the background, you can now stay focused on what's relevant to you, while navigating things across teams, departments, or projects.
</p>

<p>The process to create different workspace is quite simple states as follows: 
</p>

<ul>

    <li>Select Organizations from your Settings Menu in the lower-left 
</li>

<li>Click the + icon to add a new Workspace!
</li>
</ul>

<p><b>Pro Tip</b>: Shorten the process of creating a new Workspace even further by clicking the + symbol in your Settings Menu. This will take you directly into the onboarding process for your new Workspace.
</p>

<p>StickyTasks provides you the flexibility to be a part of as many Workspaces as you'd like, and <b>can jump between Workspaces at any time from your settings menu.</b>
</p>

<img src={protip} alt="" srcset="" style={width}/>

<p>If you need to view all workflows in one place, we recommend creating one Workspace and using privacy settings as needed.
</p>

<h3>Want to learn more?
</h3>

<p><a href="https://docs.google.com/document/d/1DSAf3RC6RhLYXy3JQ7qMMKl1xFBDXCNz/edit?usp=sharing&ouid=105931174921621543929&rtpof=true&sd=true">Onboarding Guide: Stating the Essentials
</a></p>


<a href="https://stickytasks.com/">Go to StickyTasks
</a>
    
  </Container>
  )
}

export default HowCanICreate