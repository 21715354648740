import React from "react";
import { Container, Form, Button } from "react-bootstrap";
import "./CTA.css";

const CTA = (props) => {
  const formStyle = {};

  const formInputStyle = {
    backgroundColor: "none",
    color: "white",
    border: "none",
    borderBottom: "1px solid black",
    width: "60%",
    borderRadius: "0px",
    textAlign: "center",
    margin: "auto",
  };

  const buttonStyle = {
    margin: "0",
    border: "1px solid black",
  };

  return (
    <Container fluid className="ctn">
      <div className="ctaStyle">
        <h5 className="smallcard-heading">{props.text}</h5>

        <Form className="formStyle">
          <Form.Group
            style={formInputStyle}
            className="mb-3"
            controlId="formBasicEmail"
          >
            <Form.Control
              type="email"
              style={{ border: "none", backgroundColor: "transparent" }}
              placeholder="Enter Email"
            />
          </Form.Group>

          <Button className="cta-btn" type="submit" style={buttonStyle}>
            Subscribe
          </Button>
        </Form>
      </div>
    </Container>
  );
};

export default CTA;
