import React from "react";
import Header from "./components/Universal/Header";
import Home from "./components/pages/Home";
import Contact from "./components/pages/Contact";
import Pricing from "./components/pages/Pricing";
import Blog from "./components/pages/Blog";
import Cases from "./components/pages/Cases";

import HelpCenter from "./components/HelpCenter/HelpCenter";

import ProjectM from "./components/pages/features/ProjectM";
import TasksM from "./components/pages/features/TasksM";
import ProductivityT from "./components/pages/features/ProductivityT";
import ApplicationT from "./components/pages/features/ApplicationT";
import TeamM from "./components/pages/features/TeamM";
import AttendanceM from "./components/pages/features/AttendanceM";
import EmployeeMo from "./components/pages/features/EmployeeMo";
import Payroll from "./components/pages/features/Payroll";
import TodoL from "./components/pages/features/TodoL";
import Reports from "./components/pages/features/Reports";
import Integrations from "./components/pages/features/Integrations";

import RemoteWork from "./components/pages/usecases/RemoteWork";
import Designers from "./components/pages/usecases/Designers";
import SoftwareE from "./components/pages/usecases/SoftwareE";
import SMB from "./components/pages/usecases/SMB";
import MarketingP from "./components/pages/usecases/MarketingP";

import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import Terms from "./components/pages/Terms";

import Softception from "./components/pages/cases/Softception";
import HPP from "./components/pages/cases/HPP";
import Salsoft from "./components/pages/cases/Salsoft";

import Blog1 from "./components/pages/blogs/Project-Manager-Guide";
import Blog2 from "./components/pages/blogs/5minutes-Crash-Course";
import Blog3 from "./components/pages/blogs/Project-Based-Vs-Milestone-Billing";
import Blog4 from "./components/pages/blogs/A-Definitive-Guide";
import Blog5 from "./components/pages/blogs/Managing-Crisis";
import Blog6 from "./components/pages/blogs/Navigating-the-New-Normal";

import CTA from "../src/components/Universal/CTA";
import Footer from "../src/components/Universal/Footer/Footer";
import BottomBar from "./components/Universal/BottomBar";
import ScrollToTop from "./ScrollToTop";
import { Routes, Route, Navigate } from "react-router-dom";
import HowCanAssignTask from "./components/pages/helpcenter-pages/HowCanAssignTask";
import HowCanICreate from "./components/pages/helpcenter-pages/HowCanICreate";
import Onboardguide from "./components/pages/helpcenter-pages/Onboard-guide";

function App() {
  const ctaText = `Don’t miss out on what’s new. Subscribe to our newsletter and get all the updates you need.  `;
  return (
    <div>
      <Header />
      <ScrollToTop>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/pricing" element={<Pricing />} />
          <Route
            exact
            path="/features/project-management"
            element={<ProjectM />}
          />
          <Route exact path="/features/tasks-management" element={<TasksM />} />
          <Route
            exact
            path="/features/productivity-tracking"
            element={<ProductivityT />}
          />
          <Route
            exact
            path="/features/application-tracking"
            element={<ApplicationT />}
          />
          <Route exact path="/features/team-management" element={<TeamM />} />
          <Route
            exact
            path="/features/attendance-management"
            element={<AttendanceM />}
          />
          <Route
            exact
            path="/features/employee-monitoring"
            element={<EmployeeMo />}
          />
          <Route exact path="/features/payroll" element={<Payroll />} />
          <Route exact path="/features/todo-list" element={<TodoL />} />
          <Route exact path="/features/reports" element={<Reports />} />
          <Route
            exact
            path="/features/integrations"
            element={<Integrations />}
          />

          <Route exact path="/cases/remote-work" element={<RemoteWork />} />
          <Route exact path="/cases/designers" element={<Designers />} />
          <Route
            exact
            path="/cases/software-engineers"
            element={<SoftwareE />}
          />
          <Route exact path="/cases/smb" element={<SMB />} />
          <Route exact path="/cases/marketing" element={<MarketingP />} />

          <Route exact path="/blog" element={<Blog />} />
          <Route exact path="/case-studies" element={<Cases />} />
          <Route exact path="/help" element={<HelpCenter />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/terms-and-conditions" element={<Terms />} />

          <Route
            exact
            path="case-study/softception"
            element={<Softception />}
          />
          <Route exact path="case-study/hpp" element={<HPP />} />
          <Route exact path="case-study/salsoft" element={<Salsoft />} />

          <Route exact path="blog/Project-Manager-Guide" element={<Blog1 />} />
          <Route exact path="blog/5minutes-Crash-Course" element={<Blog2 />} />
          <Route exact path="blog/Project-Based-Vs-Milestone-Billing" element={<Blog3 />} />
          <Route exact path="blog/A-Definitive-Guide" element={<Blog4 />} />
          <Route exact path="blog/Managing-Crisis" element={<Blog5 />} />
          <Route exact path="blog/Navigating-the-New-Normal" element={<Blog6 />} />

          <Route path="/features" element={<Navigate to="/" />} />
          <Route path="*" element={<Navigate to="/" replace />} />

          <Route exact path="pages/helpcenter-pages/HowCanAssignTask" element={<HowCanAssignTask />} />
          <Route exact path="pages/helpcenter-pages/HowCanICreate" element={<HowCanICreate/>} />
          <Route exact path="pages/helpcenter-pages/Onboard-guide" element={<Onboardguide />} />



        </Routes>
      </ScrollToTop>
      <CTA text={ctaText} ButtonText="Subscribe" placeholder="Enter Email" />
      <Footer />
      <BottomBar />
    </div>
  );
}

export default App;
