import React from "react";
import {Helmet} from "react-helmet";
import Featuress from "../../Features/Features";
import PricingSnippet from "../../Universal/PricingSnippet";

import accurateestimate from "../../../assets/imgs/Features/PT/PT-AccurateEstimates.png";

import EvaluatesProductivity from '../../../assets/imgs/Features/PT/PT-Evaluate-Productivity.png';

import PreciseBillableHours from '../../../assets/imgs/Features/PT/PT-PreciseBillableHours.png';

import Banner from '../../../assets/imgs/Features/PT/PT-MainDashboard.png';








function ProductivityT() {
  const priceCard = {
    heading: "Turbocharge your productivity now with our free plan.",
    desc: "No upfront fee, hidden charges, or cost overrun. Do more with less while sticking to your budget. Literally.    ",
  };

  var head = {
    title: `Finest Time Tracker`,
    desc: `Use time tracking to gain a thorough insight into workflows. Improve team focus on projects, promote accountability, and stay on top of your deadlines.`,
    img: Banner,
  };

  var sections = [
    {
      img: PreciseBillableHours,
      title: `Precise Billable Hours

      `,
      desc: `Capture everything users do while they are clocked in. As a manager, get a 360-degree view of time spent on projects and create an accurate record of billable hours.
      `,
    },
    {
      img: EvaluatesProductivity,
      title: `Evaluate Productivity`,
      desc: `Refer to activity levels and pinpoint all the factors that can distract users or hinder their performance. Leverage this data to identify gaps or discrepancies in your team's working time. `,
    },
    {
      img: accurateestimate,
      title: `Accurate Estimates`,
      desc: `Easily get an estimate of hours required for each project phase to and plan team progress in the pipeline. This software allows users to update, improve, and execute accurate quotes. 
      `,
    },
  ];

  return (
    <div>
      <Helmet>
            <title>Productivity Tracking Software: Measure and Improve Your Team's Performance - StickyTasks </title>
            <meta name="description" content="Track and measure your team's productivity with StickyTasks' productivity tracking feature." />
      </Helmet>
      <Featuress headerData={head} sectionData={sections} />
      <PricingSnippet heading={priceCard.heading} desc={priceCard.desc} />
    </div>
  );
}

export default ProductivityT;
