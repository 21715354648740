import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./pricingSnippet.css";

const PricingSnippetUC = (props) => {
  return (
    <Container fluid className="pricingSnipetCtn">
      <Row>
        <Col className="text-center">
          <h1 className="banner-heading-small mx-auto ">{props.heading}</h1>
          <p className="section-description mx-auto">{props.desc}</p>

          <a
            className="cta-btn mt-5 "
            href="https://app.stickytasks.com/Account/Register"
            target="_blank"
          >
            {props.ctaText}
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default PricingSnippetUC;
