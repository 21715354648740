import React from "react";
import rtc from "../../assets/imgs/rtc.svg";
import activity from "../../assets/imgs/activity.svg";
import tm from "../../assets/imgs/tm.svg";
import { Col, Container, Row } from "react-bootstrap";

const Features = () => {
  const featureBox = {
    // height: "26rem",
    width: "12rem",
    backgroundColor: "WHITE",
    boxShadow: "0px 6px 20px #00000029",
    borderRadius: "4px",
    textAlign: "center",
    padding: "3rem 2rem 3rem 2rem",
    "&:hover": {
      backgroundColor: "#111",
    },
  };

  const containerStyle = {
    textAlign: "center",
  };

  const rowStyle = {
    margin: "auto",
    gap: "2rem",
  };

  const imgStyle = {
    marginTop: "1rem",
    transform: "scale(.8)",
  };

  return (
    <Container fluid style={containerStyle} className="normal-container whyst-ctn">
      <Row className="col-10" style={rowStyle}>
        <h2 className="section-heading">Why StickyTasks?</h2>
        <Col style={featureBox}>
          <h4 className="card-heading">Real-Time Control</h4>
          <img src={rtc} style={imgStyle} />
          <p className="card-description mt-5">
            Streamline your project pipelines. Prioritize high-impact workloads,
            monitor events, and dependencies. Ensure timely, on-budget delivery.
          </p>
        </Col>

        <Col style={featureBox}>
          <h4 className="card-heading">Activity Monitoring</h4>
          <img src={activity} style={imgStyle} />
          <p className="card-description mt-5">
            View who’s idle, on break, and offline. Use timesheets to see what
            teams need to work on and when. Plan for any changes or delays so
            that progress never stops.
          </p>
        </Col>
        <Col style={featureBox}>
          <h4 className="card-heading">Task Management</h4>
          <img src={tm} style={imgStyle} />
          <p className="card-description mt-5">
            Keep tasks, files, conversations, and deliverables in one place.
            Customize workflows exactly how you want them. Break team silos and
            get the job done.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Features;
